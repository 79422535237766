// src/restaurant/pages/OrderDetails.js
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { GoogleMap, Marker, DirectionsRenderer } from '@react-google-maps/api';
import { useGoogleMaps } from '../../components/GoogleMapsProvider';
import RestaurantSidebar from '../../components/RestaurantSidebar';
import RestaurantHeader from '../../components/RestaurantHeader';
import './OrderDetails.css';

const OrderDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { isLoaded } = useGoogleMaps();
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(true);
  const [directions, setDirections] = useState(null);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  useEffect(() => {
    const fetchOrderDetails = async () => {
      if (!id) return;

      try {
        const orderDoc = await getDoc(doc(db, 'orders', id));
        if (!orderDoc.exists()) {
          setError('لم يتم العثور على الطلب');
          setLoading(false);
          return;
        }

        const orderData = { id: orderDoc.id, ...orderDoc.data() };
        setOrder(orderData);

        // جلب الاتجاهات إذا كانت خرائط Google محملة
        if (isLoaded && orderData.restaurantLocation && orderData.customerLocation) {
          const directionsService = new window.google.maps.DirectionsService();
          
          directionsService.route(
            {
              origin: orderData.restaurantLocation,
              destination: orderData.customerLocation,
              travelMode: window.google.maps.TravelMode.DRIVING
            },
            (result, status) => {
              if (status === window.google.maps.DirectionsStatus.OK) {
                setDirections(result);
              }
            }
          );
        }
      } catch (error) {
        console.error('خطأ في جلب تفاصيل الطلب:', error);
        setError('حدث خطأ في جلب تفاصيل الطلب');
      } finally {
        setLoading(false);
      }
    };

    fetchOrderDetails();
  }, [id, isLoaded]);

  const cancelOrder = async () => {
    if (!order) return;
    
    if (window.confirm('هل أنت متأكد من إلغاء هذا الطلب؟')) {
      try {
        await updateDoc(doc(db, 'orders', id), { status: 'ملغي ❌' });
        setOrder({ ...order, status: 'ملغي ❌' });
        setSuccess('تم إلغاء الطلب بنجاح');
      } catch (error) {
        console.error('خطأ في إلغاء الطلب:', error);
        setError('حدث خطأ في إلغاء الطلب');
      }
    }
  };

  const formatTimestamp = (timestamp) => {
    if (!timestamp || !timestamp.toDate) return 'غير متاح';
    
    const date = timestamp.toDate();
    return date.toLocaleDateString('ar-EG', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  if (loading) {
    return (
      <div className="restaurant-loading">
        <div className="restaurant-spinner"></div>
        <p>جاري تحميل البيانات...</p>
      </div>
    );
  }

  return (
    <div className="restaurant-order-details-page">
      <RestaurantSidebar />
      <div className="restaurant-order-details-content">
        <RestaurantHeader restaurantName={localStorage.getItem('restaurantName') || 'المطعم'} />
        
        <div className="order-details-container">
          <div className="order-details-header">
            <div>
              <h1>تفاصيل الطلب #{id}</h1>
              {order?.createdAt && (
                <p className="order-date">تاريخ الطلب: {formatTimestamp(order.createdAt)}</p>
              )}
            </div>
            
            <div className="order-status-badge" data-status={order?.status}>
              {order?.status}
            </div>
          </div>
          
          {error && <div className="error-message">{error}</div>}
          {success && <div className="success-message">{success}</div>}
          
          {order ? (
            <div className="order-details-grid">
              <div className="order-info-section">
                <div className="order-card">
                  <h2>معلومات العميل</h2>
                  <div className="order-info-item">
                    <div className="info-label">الاسم:</div>
                    <div className="info-value">{order.customerName}</div>
                  </div>
                  <div className="order-info-item">
                    <div className="info-label">رقم الهاتف:</div>
                    <div className="info-value">{order.phone}</div>
                  </div>
                  <div className="order-info-item">
                    <div className="info-label">العنوان:</div>
                    <div className="info-value">{order.address}</div>
                  </div>
                  {order.notes && (
                    <div className="order-info-item">
                      <div className="info-label">ملاحظات:</div>
                      <div className="info-value">{order.notes}</div>
                    </div>
                  )}
                </div>
                
                <div className="order-card">
                  <h2>تفاصيل الطلب</h2>
                  <div className="order-info-item">
                    <div className="info-label">قيمة الطلب:</div>
                    <div className="info-value">{order.amount?.toFixed(3)} د.ك</div>
                  </div>
                  <div className="order-info-item">
                    <div className="info-label">طريقة الدفع:</div>
                    <div className="info-value">{order.paymentMethod}</div>
                  </div>
                  <div className="order-info-item">
                    <div className="info-label">السائق:</div>
                    <div className="info-value">{order.driverName}</div>
                  </div>
                  <div className="order-info-item">
                    <div className="info-label">المسافة:</div>
                    <div className="info-value">{order.distance?.toFixed(2)} كم</div>
                  </div>
                  <div className="order-info-item">
                    <div className="info-label">رسوم التوصيل:</div>
                    <div className="info-value">{order.deliveryFee?.toFixed(3)} د.ك</div>
                  </div>
                </div>
                
                <div className="order-actions">
                  {order.status !== 'ملغي ❌' && order.status !== 'تم التسليم ✅' && (
                    <button className="cancel-order-btn" onClick={cancelOrder}>
                      إلغاء الطلب
                    </button>
                  )}
                  <button 
                    className="back-to-orders-btn" 
                    onClick={() => navigate('/restaurant/orders')}
                  >
                    العودة إلى قائمة الطلبات
                  </button>
                </div>
              </div>
              
              <div className="order-map-section">
                <div className="map-container">
                  {isLoaded ? (
                    <GoogleMap
                      mapContainerStyle={{ width: '100%', height: '100%' }}
                      center={order.restaurantLocation || { lat: 29.3759, lng: 47.9774 }}
                      zoom={12}
                      options={{
                        streetViewControl: false,
                        fullscreenControl: false
                      }}
                    >
                      {/* موقع المطعم */}
                      {order.restaurantLocation && (
                        <Marker
                          position={order.restaurantLocation}
                          icon={{
                            url: '/images/restaurant-marker.png',
                            scaledSize: new window.google.maps.Size(40, 40)
                          }}
                        />
                      )}
                      
                      {/* موقع العميل */}
                      {order.customerLocation && (
                        <Marker
                          position={order.customerLocation}
                          icon={{
                            url: '/images/customer-marker.png',
                            scaledSize: new window.google.maps.Size(40, 40)
                          }}
                        />
                      )}
                      
                      {/* مسار التوصيل */}
                      {directions && (
                        <DirectionsRenderer
                          directions={directions}
                          options={{
                            suppressMarkers: true,
                            polylineOptions: {
                              strokeColor: '#4a90e2',
                              strokeWeight: 5
                            }
                          }}
                        />
                      )}
                    </GoogleMap>
                  ) : (
                    <div className="map-loading">جاري تحميل الخريطة...</div>
                  )}
                </div>
                
                <div className="order-status-timeline">
                  <h2>حالة التوصيل</h2>
                  <div className="timeline">
                    <div className={`timeline-item ${order.status !== 'ملغي ❌' ? 'active' : ''}`}>
                      <div className="timeline-icon">📝</div>
                      <div className="timeline-content">
                        <h3>تم استلام الطلب</h3>
                        <p>{formatTimestamp(order.createdAt)}</p>
                      </div>
                    </div>
                    
                    <div className={`timeline-item ${order.status === 'قيد التنفيذ 🚀' || order.status === 'تم الاستلام 🚗' || order.status === 'تم التسليم ✅' ? 'active' : ''}`}>
                    // تكملة OrderDetails.js

<div className="timeline-icon">🚚</div>
<div className="timeline-content">
  <h3>جارٍ التوصيل</h3>
  <p>السائق في الطريق</p>
</div>
</div>

<div className={`timeline-item ${order.status === 'تم الاستلام 🚗' || order.status === 'تم التسليم ✅' ? 'active' : ''}`}>
<div className="timeline-icon">📦</div>
<div className="timeline-content">
  <h3>تم استلام الطلب من المطعم</h3>
  <p>السائق استلم الطلب</p>
</div>
</div>

<div className={`timeline-item ${order.status === 'تم التسليم ✅' ? 'active' : ''}`}>
<div className="timeline-icon">✅</div>
<div className="timeline-content">
  <h3>تم التسليم</h3>
  <p>تم تسليم الطلب للعميل</p>
</div>
</div>

{order.status === 'ملغي ❌' && (
<div className="timeline-item active cancelled">
  <div className="timeline-icon">❌</div>
  <div className="timeline-content">
    <h3>تم إلغاء الطلب</h3>
    <p>تم إلغاء الطلب</p>
  </div>
</div>
)}
</div>
</div>
</div>
</div>
) : (
<div className="no-order-found">
<p>لم يتم العثور على الطلب المطلوب</p>
<button 
className="back-to-orders-btn" 
onClick={() => navigate('/restaurant/orders')}
>
العودة إلى قائمة الطلبات
</button>
</div>
)}
</div>
</div>
</div>
);
};

export default OrderDetails;