// src/restaurant/pages/Reports.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, query, where, getDocs, orderBy } from 'firebase/firestore';
import { db, auth } from '../../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import RestaurantSidebar from '../../components/RestaurantSidebar';
import RestaurantHeader from '../../components/RestaurantHeader';
import './Reports.css';

const Reports = () => {
  const [orders, setOrders] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dateRange, setDateRange] = useState('week'); // 'day', 'week', 'month', 'year'
  const [reportType, setReportType] = useState('orders'); // 'orders', 'finance'
  const [stats, setStats] = useState({
    totalOrders: 0,
    completedOrders: 0,
    cancelledOrders: 0,
    totalRevenue: 0,
    totalDeliveryFees: 0,
    avgOrderValue: 0
  });
  
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (!user) {
        navigate('/restaurant/login');
        return;
      }

      const restaurantId = localStorage.getItem('restaurantId');
      if (!restaurantId) {
        navigate('/restaurant/login');
        return;
      }

      await fetchData(restaurantId);
    });

    return () => unsubscribe();
  }, [navigate]);

  useEffect(() => {
    calculateStats();
  }, [orders, transactions, dateRange]);

  const fetchData = async (restaurantId) => {
    try {
      setLoading(true);
      
      // جلب الطلبات
      const startDate = getStartDateFromRange(dateRange);
      
      const ordersRef = collection(db, 'orders');
      const ordersQuery = query(
        ordersRef,
        where('restaurantId', '==', restaurantId),
        where('createdAt', '>=', startDate),
        orderBy('createdAt', 'desc')
      );
      
      const ordersSnapshot = await getDocs(ordersQuery);
      const ordersData = ordersSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        date: doc.data().createdAt ? doc.data().createdAt.toDate() : new Date()
      }));
      
      setOrders(ordersData);
      
      // جلب المعاملات المالية
      const transactionsRef = collection(db, 'transactions');
      const transactionsQuery = query(
        transactionsRef,
        where('restaurantId', '==', restaurantId),
        where('timestamp', '>=', startDate),
        orderBy('timestamp', 'desc')
      );
      
      const transactionsSnapshot = await getDocs(transactionsQuery);
      const transactionsData = transactionsSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        date: doc.data().timestamp ? doc.data().timestamp.toDate() : new Date()
      }));
      
      setTransactions(transactionsData);
    } catch (error) {
      console.error('خطأ في جلب البيانات:', error);
    } finally {
      setLoading(false);
    }
  };

  const getStartDateFromRange = (range) => {
    const now = new Date();
    const startDate = new Date();
    
    switch(range) {
      case 'day':
        startDate.setHours(0, 0, 0, 0);
        break;
      case 'week':
        startDate.setDate(now.getDate() - 7);
        break;
      case 'month':
        startDate.setMonth(now.getMonth() - 1);
        break;
      case 'year':
        startDate.setFullYear(now.getFullYear() - 1);
        break;
      default:
        startDate.setDate(now.getDate() - 7);
    }
    
    return startDate;
  };

  const calculateStats = () => {
    if (orders.length === 0) {
      setStats({
        totalOrders: 0,
        completedOrders: 0,
        cancelledOrders: 0,
        totalRevenue: 0,
        totalDeliveryFees: 0,
        avgOrderValue: 0
      });
      return;
    }

    const totalOrders = orders.length;
    const completedOrders = orders.filter(order => order.status === 'تم التسليم ✅').length;
    const cancelledOrders = orders.filter(order => order.status === 'ملغي ❌').length;
    
    const totalRevenue = orders
      .filter(order => order.status === 'تم التسليم ✅')
      .reduce((sum, order) => sum + (order.amount || 0), 0);
    
    const totalDeliveryFees = orders
      .reduce((sum, order) => sum + (order.deliveryFee || 0), 0);
    
    const avgOrderValue = completedOrders > 0 
      ? totalRevenue / completedOrders 
      : 0;

    setStats({
      totalOrders,
      completedOrders,
      cancelledOrders,
      totalRevenue,
      totalDeliveryFees,
      avgOrderValue
    });
  };

  const formatCurrency = (amount) => {
    return amount.toFixed(3) + ' د.ك';
  };

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString('ar-EG', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  const getRangeTitle = () => {
    switch(dateRange) {
      case 'day':
        return 'اليوم';
      case 'week':
        return 'آخر أسبوع';
      case 'month':
        return 'آخر شهر';
      case 'year':
        return 'آخر سنة';
      default:
        return 'آخر أسبوع';
    }
  };

  if (loading) {
    return (
      <div className="restaurant-loading">
        <div className="restaurant-spinner"></div>
        <p>جاري تحميل البيانات...</p>
      </div>
    );
  }

  return (
    <div className="restaurant-reports-page">
      <RestaurantSidebar />
      <div className="restaurant-reports-content">
        <RestaurantHeader restaurantName={localStorage.getItem('restaurantName') || 'المطعم'} />
        
        <div className="reports-container">
          <div className="reports-header">
            <h1>التقارير والإحصائيات</h1>
            <div className="reports-controls">
              <div className="range-selector">
                <label>الفترة الزمنية:</label>
                <select 
                  value={dateRange} 
                  onChange={(e) => setDateRange(e.target.value)}
                >
                  <option value="day">اليوم</option>
                  <option value="week">آخر أسبوع</option>
                  <option value="month">آخر شهر</option>
                  <option value="year">آخر سنة</option>
                </select>
              </div>
              
              <div className="report-type-tabs">
                <button 
                  className={`report-tab ${reportType === 'orders' ? 'active' : ''}`}
                  onClick={() => setReportType('orders')}
                >
                  تقرير الطلبات
                </button>
                <button 
                  className={`report-tab ${reportType === 'finance' ? 'active' : ''}`}
                  onClick={() => setReportType('finance')}
                >
                  التقرير المالي
                </button>
              </div>
            </div>
          </div>
          
          <div className="report-stats-cards">
            {reportType === 'orders' ? (
              <>
                <div className="stat-card">
                  <div className="stat-value">{stats.totalOrders}</div>
                  <div className="stat-label">إجمالي الطلبات</div>
                </div>
                
                <div className="stat-card">
                  <div className="stat-value">{stats.completedOrders}</div>
                  <div className="stat-label">الطلبات المكتملة</div>
                </div>
                
                <div className="stat-card">
                  <div className="stat-value">{stats.cancelledOrders}</div>
                  <div className="stat-label">الطلبات الملغاة</div>
                </div>
                
                <div className="stat-card">
                  <div className="stat-value">
                    {(stats.completedOrders / (stats.totalOrders || 1) * 100).toFixed(1)}%
                  </div>
                  <div className="stat-label">نسبة إكمال الطلبات</div>
                </div>
              </>
            ) : (
              <>
                <div className="stat-card">
                  <div className="stat-value">{formatCurrency(stats.totalRevenue)}</div>
                  <div className="stat-label">إجمالي المبيعات</div>
                </div>
                
                <div className="stat-card">
                  <div className="stat-value">{formatCurrency(stats.totalDeliveryFees)}</div>
                  <div className="stat-label">رسوم التوصيل</div>
                </div>
                
                <div className="stat-card">
                  <div className="stat-value">{formatCurrency(stats.avgOrderValue)}</div>
                  <div className="stat-label">متوسط قيمة الطلب</div>
                </div>
                
                <div className="stat-card">
                  <div className="stat-value">
                    {formatCurrency(stats.totalRevenue - stats.totalDeliveryFees)}
                  </div>
                  <div className="stat-label">صافي المبيعات</div>
                </div>
              </>
            )}
          </div>
          
          <div className="report-data-section">
            <h2>{reportType === 'orders' ? 'تفاصيل الطلبات' : 'تفاصيل المعاملات المالية'} - {getRangeTitle()}</h2>
            
            {reportType === 'orders' ? (
              orders.length === 0 ? (
                <div className="no-data">
                  <p>لا توجد طلبات في الفترة المحددة</p>
                </div>
              ) : (
                <div className="table-container">
                  <table className="report-table">
                    <thead>
                      <tr>
                        <th>رقم الطلب</th>
                        <th>التاريخ</th>
                        <th>العميل</th>
                        <th>السائق</th>
                        <th>المبلغ</th>
                        <th>رسوم التوصيل</th>
                        <th>الحالة</th>
                      </tr>
                    </thead>
                    <tbody>
                      {orders.map((order) => (
                        <tr key={order.id}>
                          <td className="order-id">{order.id.slice(0, 8)}...</td>
                          <td>{formatDate(order.date)}</td>
                          <td>{order.customerName}</td>
                          <td>{order.driverName}</td>
                          <td>{order.amount?.toFixed(3)} د.ك</td>
                          <td>{order.deliveryFee?.toFixed(3)} د.ك</td>
                          <td>
                            <span className={`status-badge ${
                              order.status === 'تم التسليم ✅' ? 'completed' : 
                              order.status === 'ملغي ❌' ? 'cancelled' : 'pending'
                            }`}>
                              {order.status}
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )
            ) : (
              transactions.length === 0 ? (
                <div className="no-data">
                  <p>لا توجد معاملات مالية في الفترة المحددة</p>
                </div>
              ) : (
                <div className="table-container">
                  <table className="report-table">
                    <thead>
                      <tr>
                        <th>التاريخ</th>
                        <th>الوصف</th>
                        <th>نوع المعاملة</th>
                        <th>طريقة الدفع</th>
                        <th>المبلغ</th>
                      </tr>
                    </thead>
                    <tbody>
                      {transactions.map((transaction) => (
                        <tr key={transaction.id}>
                          <td>{formatDate(transaction.date)}</td>
                          <td>{transaction.description}</td>
                          <td>
                            <span className={`transaction-type ${transaction.type === 'شحن' ? 'deposit' : 'deduction'}`}>
                              {transaction.type === 'شحن' ? 'إيداع' : 'خصم'}
                            </span>
                          </td>
                          <td>{transaction.paymentMethod || '-'}</td>
                          <td className={transaction.type === 'شحن' ? 'positive-amount' : 'negative-amount'}>
                            {transaction.type === 'شحن' ? '+' : '-'} {transaction.amount?.toFixed(3)} د.ك
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reports;