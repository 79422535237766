// src/components/GoogleMapsProvider.js
import React, { createContext, useState, useEffect, useContext } from 'react';

const GoogleMapsContext = createContext({ isLoaded: false });

export const GoogleMapsProvider = ({ children }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [loadError, setLoadError] = useState(null);

  useEffect(() => {
    // Don't reload if already loaded
    if (window.google && window.google.maps) {
      setIsLoaded(true);
      return;
    }

    // Define callback function for when the script loads
    window.initGoogleMapsCallback = () => {
      setIsLoaded(true);
      console.log('✅ Google Maps loaded successfully');
    };

    // Create script element
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyAfpEXpKaRnVTfOdRpZaHBhoGCGlsHbVE8&libraries=places&callback=initGoogleMapsCallback`;
    script.async = true;
    script.defer = true;
    script.id = 'google-maps-script';
    
    // Handle errors
    script.onerror = (error) => {
      setLoadError(error);
      console.error('❌ Error loading Google Maps:', error);
    };
    
    // Add to document
    document.head.appendChild(script);
    
    // Cleanup
    return () => {
      window.initGoogleMapsCallback = null;
      if (document.getElementById('google-maps-script')) {
        document.getElementById('google-maps-script').remove();
      }
    };
  }, []);

  return (
    <GoogleMapsContext.Provider value={{ isLoaded, loadError }}>
      {children}
    </GoogleMapsContext.Provider>
  );
};

export const useGoogleMaps = () => useContext(GoogleMapsContext);