import React, { useState, useEffect } from "react";
import { db } from "../firebase";
import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
} from "firebase/firestore";
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { GoogleMap, Marker } from "@react-google-maps/api";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { useGoogleMaps } from "../components/GoogleMapsProvider";

// تهيئة مفتاح Mapbox
mapboxgl.accessToken = 'pk.eyJ1IjoibXJjY2NyZXciLCJhIjoiY204ZG1kNGx0MmVlZDJqcXU4a25xbGRibCJ9.hoeEc8I4yW_fg0ZBOWD9LA';

const MapboxAddressSearch = ({ onSelect }) => {
  const [query, setQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [loading, setLoading] = useState(false);

  const searchAddresses = async (input) => {
    if (input.length < 3) return;

    setLoading(true);
    try {
      const response = await fetch(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(input)}.json?` +
        `access_token=${mapboxgl.accessToken}&` +
        `country=KW&` + // تخصيص البحث للكويت
        `language=ar`
      );

      const data = await response.json();
      setSuggestions(data.features);
      setLoading(false);
    } catch (error) {
      console.error('خطأ في البحث:', error);
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setQuery(value);
    searchAddresses(value);
  };

  const handleSelectAddress = (address) => {
    setQuery(address.place_name);
    onSelect({
      address: address.place_name,
      location: {
        lat: address.center[1],
        lng: address.center[0]
      }
    });
    setSuggestions([]);
  };

  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <input
        type="text"
        value={query}
        onChange={handleInputChange}
        placeholder="ابحث عن العنوان في الكويت"
        style={{ 
          display: "block", 
          width: "100%", 
          padding: "8px", 
          marginBottom: "10px" 
        }}
      />
      {loading && <div>جاري البحث...</div>}
      {suggestions.length > 0 && (
        <ul style={{
          position: 'absolute',
          top: '100%',
          left: 0,
          width: '100%',
          maxHeight: '200px',
          overflowY: 'auto',
          border: '1px solid #ddd',
          backgroundColor: 'white',
          zIndex: 1000
        }}>
          {suggestions.map((suggestion, index) => (
            <li 
              key={index}
              onClick={() => handleSelectAddress(suggestion)}
              style={{
                padding: '8px',
                cursor: 'pointer',
                ':hover': { backgroundColor: '#f0f0f0' }
              }}
            >
              {suggestion.place_name}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

const RestaurantManagement = () => {
  const [restaurants, setRestaurants] = useState([]);
  const [loading, setLoading] = useState(true);
  const [newRestaurant, setNewRestaurant] = useState({
    name: "",
    email: "",
    phone: "",
    password: "",
    area: "",
    address: "",
    balance: 0,
    status: "نشط",
    location: { lat: 29.3759, lng: 47.9774 },
  });
  const [editingRestaurant, setEditingRestaurant] = useState(null);
  const [updatedData, setUpdatedData] = useState({
    name: "",
    status: "",
    balance: 0,
  });
  
  const kuwaitAreas = ["العاصمة", "حولي", "الفروانية", "الأحمدي", "مبارك الكبير", "الجهراء"];
  const { isLoaded, loadError } = useGoogleMaps();

  // جلب بيانات المطاعم عند تحميل المكون
  useEffect(() => {
    const fetchRestaurants = async () => {
      try {
        setLoading(true);
        console.log("📌 جاري جلب بيانات المطاعم...");
        const querySnapshot = await getDocs(collection(db, "restaurants"));

        if (querySnapshot.empty) {
          console.warn("⚠️ لا توجد بيانات متاحة في Firestore.");
          setRestaurants([]);
        } else {
          const data = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
            location: doc.data().location || { lat: 29.3759, lng: 47.9774 }
          }));
          console.log("📌 البيانات المسترجعة:", data);
          setRestaurants(data);
        }
      } catch (error) {
        console.error("❌ خطأ في جلب بيانات المطاعم:", error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchRestaurants();
  }, []);

  // معالجة اختيار العنوان
  const handleAddressSelect = (selectedAddress) => {
    setNewRestaurant({
      ...newRestaurant,
      address: selectedAddress.address,
      location: {
        lat: selectedAddress.location.lat,
        lng: selectedAddress.location.lng
      }
    });
  };

  // إضافة مطعم جديد
  const addRestaurant = async () => {
    if (!newRestaurant.name || !newRestaurant.email || !newRestaurant.phone || !newRestaurant.password || !newRestaurant.area || !newRestaurant.address) {
      alert("❌ يجب ملء جميع الحقول!");
      return;
    }

    try {
      setLoading(true);
      const docRef = await addDoc(collection(db, "restaurants"), newRestaurant);
      setRestaurants([...restaurants, { id: docRef.id, ...newRestaurant }]);
      alert("✅ تم إضافة المطعم بنجاح!");
      setNewRestaurant({
        name: "",
        email: "",
        phone: "",
        password: "",
        area: "",
        address: "",
        balance: 0,
        status: "نشط",
        location: { lat: 29.3759, lng: 47.9774 },
      });
    } catch (error) {
      console.error("❌ خطأ في إضافة المطعم:", error.message);
    } finally {
      setLoading(false);
    }
  };

  // تعديل بيانات مطعم
  const startEditing = (restaurant) => {
    setEditingRestaurant(restaurant.id);
    setUpdatedData({
      name: restaurant.name,
      email: restaurant.email,
      phone: restaurant.phone,
      password: restaurant.password,
      area: restaurant.area,
      status: restaurant.status,
      balance: restaurant.balance,
    });
  };

  const saveChanges = async () => {
    try {
      setLoading(true);
      await updateDoc(doc(db, "restaurants", editingRestaurant), updatedData);
      
      // تحديث البيانات في State
      setRestaurants(restaurants.map(restaurant => 
        restaurant.id === editingRestaurant 
          ? { ...restaurant, ...updatedData } 
          : restaurant
      ));
      
      alert("✅ تم تعديل المطعم بنجاح!");
      setEditingRestaurant(null);
    } catch (error) {
      console.error("❌ خطأ في تعديل بيانات المطعم:", error.message);
    } finally {
      setLoading(false);
    }
  };

  // حذف مطعم
  const deleteRestaurant = async (restaurantId) => {
    if (window.confirm("هل أنت متأكد أنك تريد حذف هذا المطعم؟")) {
      try {
        setLoading(true);
        await deleteDoc(doc(db, "restaurants", restaurantId));
        setRestaurants(restaurants.filter((r) => r.id !== restaurantId));
        alert("✅ تم حذف المطعم بنجاح!");
      } catch (error) {
        console.error("❌ خطأ في حذف المطعم:", error.message);
      } finally {
        setLoading(false);
      }
    }
  };

  // تحديث الرصيد
  const updateBalance = async (restaurantId, amount) => {
    try {
      setLoading(true);
      const restaurantRef = doc(db, "restaurants", restaurantId);
      await updateDoc(restaurantRef, { balance: amount });
      setRestaurants((prev) =>
        prev.map((r) => (r.id === restaurantId ? { ...r, balance: amount } : r))
      );
      alert("✅ تم تحديث الرصيد بنجاح!");
    } catch (error) {
      console.error("❌ خطأ في تحديث الرصيد:", error.message);
    } finally {
      setLoading(false);
    }
  };

  // تحديث حالة المطعم (نشط/غير نشط)
  const toggleRestaurantStatus = async (restaurant) => {
    try {
      setLoading(true);
      const newStatus = restaurant.status === "نشط" ? "غير نشط" : "نشط";
      await updateDoc(doc(db, "restaurants", restaurant.id), { status: newStatus });
      setRestaurants((prev) =>
        prev.map((r) => (r.id === restaurant.id ? { ...r, status: newStatus } : r))
      );
      alert(`✅ تم ${newStatus === "نشط" ? "تفعيل" : "إيقاف"} المطعم بنجاح!`);
    } catch (error) {
      console.error("❌ خطأ في تحديث حالة المطعم:", error.message);
    } finally {
      setLoading(false);
    }
  };

  // مكون عرض حالة التحميل
  const LoadingSpinner = () => (
    <div style={{
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "rgba(255, 255, 255, 0.7)",
      zIndex: 1000
    }}>
      <div style={{
        width: "50px",
        height: "50px",
        border: "5px solid #f3f3f3",
        borderTop: "5px solid #3498db",
        borderRadius: "50%",
        animation: "spin 1s linear infinite"
      }} />
    </div>
  );

  // معالجة أخطاء تحميل الخريطة
  if (loadError) {
    return (
      <div>
        <Header />
        <div style={{ textAlign: 'center', marginTop: '50px' }}>
          <h2>خطأ في تحميل الخريطة</h2>
          <p>{loadError.message}</p>
        </div>
        <Footer />
      </div>
    );
  }

  return (
    <div>
      <Header />
      {loading && <LoadingSpinner />}
      <div style={{ display: "flex", justifyContent: "space-between", marginTop: "50px" }}>
        
        {/* خريطة المطاعم */}
        <div style={{ width: "40%", height: "500px" }}>
          {isLoaded ? (
            <GoogleMap 
              mapContainerStyle={{ width: "100%", height: "100%" }} 
              center={{ lat: 29.3759, lng: 47.9774 }} 
              zoom={10}
            >
              {restaurants.map((restaurant) => (
                <Marker 
                  key={restaurant.id} 
                  position={restaurant.location} 
                  label={restaurant.name}
                  icon={{
                    url: restaurant.status === "نشط" ? "/green-restaurant.png" : "/red-restaurant.png",
                  }} 
                />
              ))}
            </GoogleMap>
          ) : (
            <div style={{ 
              width: "100%", 
              height: "100%", 
              display: "flex", 
              justifyContent: "center", 
              alignItems: "center",
              backgroundColor: "#f8f9fa",
              borderRadius: "5px" 
            }}>
              <p>جاري تحميل الخريطة...</p>
            </div>
          )}
        </div>

        {/* إدارة المطاعم */}
        <div style={{ width: "55%" }}>
          <h2 style={{ marginBottom: "15px" }}>إدارة المطاعم</h2>
          
          {/* نموذج إضافة مطعم جديد */}
          <div style={{ 
            border: "1px solid #ddd", 
            padding: "15px", 
            textAlign: "left", 
            background: "#f9f9f9", 
            borderRadius: "5px",
            marginBottom: "20px"
          }}>
            <h3 style={{ margin: "0 0 15px 0" }}>إضافة مطعم جديد</h3>
            
            <input 
              type="text" 
              placeholder="اسم المطعم" 
              value={newRestaurant.name} 
              onChange={(e) => setNewRestaurant({ ...newRestaurant, name: e.target.value })}
              style={{ display: "block", width: "100%", padding: "8px", marginBottom: "10px" }}
            />
            
            <input 
              type="email" 
              placeholder="الإيميل" 
              value={newRestaurant.email} 
              onChange={(e) => setNewRestaurant({ ...newRestaurant, email: e.target.value })}
              style={{ display: "block", width: "100%", padding: "8px", marginBottom: "10px" }}
            />
            
            <input 
              type="text" 
              placeholder="رقم الهاتف" 
              value={newRestaurant.phone} 
              onChange={(e) => setNewRestaurant({ ...newRestaurant, phone: e.target.value })}
              style={{ display: "block", width: "100%", padding: "8px", marginBottom: "10px" }}
            />
            
            <input 
              type="password" 
              placeholder="كلمة المرور" 
              value={newRestaurant.password} 
              onChange={(e) => setNewRestaurant({ ...newRestaurant, password: e.target.value })}
              style={{ display: "block", width: "100%", padding: "8px", marginBottom: "10px" }}
            />
            
            <select 
              value={newRestaurant.area} 
              onChange={(e) => setNewRestaurant({ ...newRestaurant, area: e.target.value })}
              style={{ display: "block", width: "100%", padding: "8px", marginBottom: "10px" }}
            >
              <option value="">اختر المنطقة</option>
              {kuwaitAreas.map((area) => (
                <option key={area} value={area}>
                  {area}
                </option>
              ))}
            </select>

            <MapboxAddressSearch onSelect={handleAddressSelect} />

            <button 
              onClick={addRestaurant} 
              disabled={loading}
              style={{
                backgroundColor: "#28a745",
                color: "white",
                border: "none",
                borderRadius: "4px",
                padding: "10px 15px",
                cursor: loading ? "not-allowed" : "pointer",
                fontWeight: "bold"
              }}
            >
              إضافة المطعم
            </button>
          </div>

          {/* جدول المطاعم */}
          {restaurants.length === 0 && !loading ? (
            <div style={{ 
              padding: "20px", 
              backgroundColor: "#f8f9fa", 
              borderRadius: "5px",
              textAlign: "center" 
            }}>
              <p>⚠️ لا توجد مطاعم متاحة.</p>
            </div>
          ) : (
            <div style={{ overflowX: "auto" }}>
              <table border="1" width="100%" style={{ margin: "0 auto", textAlign: "center", borderCollapse: "collapse" }}>
                <thead style={{ backgroundColor: "#f8f9fa" }}>
                  <tr>
                    <th style={{ padding: "10px" }}>رقم المطعم</th>
                    <th style={{ padding: "10px" }}>اسم المطعم</th>
                    <th style={{ padding: "10px" }}>الحالة</th>
                    <th style={{ padding: "10px" }}>الايميل</th>
                    <th style={{ padding: "10px" }}>الرصيد</th>
                    <th style={{ padding: "10px" }}>الإجراءات</th>
                  </tr>
                </thead>
                <tbody>
                  {restaurants.map((restaurant, index) => (
                    <tr key={restaurant.id}>
                      <td style={{ padding: "8px" }}>{index + 1}</td>
                      <td style={{ padding: "8px" }}>{restaurant.name}</td>
                      <td style={{ padding: "8px" }}>
                        <span style={{
                          padding: "4px 8px",
                          borderRadius: "4px",
                          backgroundColor: restaurant.status === "نشط" ? "#d4edda" : "#f8d7da",
                          color: restaurant.status === "نشط" ? "#155724" : "#721c24"
                        }}>
                          {restaurant.status}
                        </span>
                      </td>
                      <td style={{ padding: "8px" }}>{restaurant.email}</td>
                      <td style={{ padding: "8px" }}>{restaurant.balance} دينار</td>
                      <td style={{ padding: "8px" }}>
                        <button 
                          onClick={() => startEditing(restaurant)}
                          style={{
                            backgroundColor: "#17a2b8",
                            color: "white",
                            border: "none",
                            borderRadius: "4px",
                            padding: "5px 10px",
                            margin: "2px",
                            cursor: loading ? "not-allowed" : "pointer"
                          }}
                        >
                          ✏️ تعديل
                        </button>
                        
                        {editingRestaurant === restaurant.id && (
                          <button 
                            onClick={saveChanges}
                            style={{
                              backgroundColor: "#ffc107",
                              color: "black",
                              border: "none",
                              borderRadius: "4px",
                              padding: "5px 10px",
                              margin: "2px",
                              cursor: loading ? "not-allowed" : "pointer"
                            }}
                          >
                            💾 حفظ التعديلات
                          </button>
                        )}
                        
                        <button 
                          onClick={() => deleteRestaurant(restaurant.id)}
                          style={{
                            backgroundColor: "#dc3545",
                            color: "white",
                            border: "none",
                            borderRadius: "4px",
                            padding: "5px 10px",
                            margin: "2px",
                            cursor: loading ? "not-allowed" : "pointer"
                          }}
                        >
                          🗑️ حذف
                        </button>
                        
                        <button 
                          onClick={() => updateBalance(restaurant.id, restaurant.balance + 10)}
                          style={{
                            backgroundColor: "#6c757d",
                            color: "white",
                            border: "none",
                            borderRadius: "4px",
                            padding: "5px 10px",
                            margin: "2px",
                            cursor: loading ? "not-allowed" : "pointer"
                          }}
                        >
                          💰 شحن 10 دينار
                        </button>
                        
                        <button 
                          onClick={() => toggleRestaurantStatus(restaurant)}
                          style={{
                            backgroundColor: restaurant.status === "نشط" ? "#dc3545" : "#28a745",
                            color: "white",
                            border: "none",
                            borderRadius: "4px",
                            padding: "5px 10px",
                            margin: "2px",
                            cursor: loading ? "not-allowed" : "pointer"
                          }}
                        >
                          {restaurant.status === "نشط" ? "🔴 إيقاف" : "🟢 تفعيل"}
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default RestaurantManagement;