// src/restaurant/pages/RestaurantDashboard.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, query, where, getDocs, doc, getDoc } from 'firebase/firestore';
import { db, auth } from '../../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import RestaurantSidebar from '../../components/RestaurantSidebar';
import RestaurantHeader from '../../components/RestaurantHeader';
import './RestaurantDashboard.css';

const RestaurantDashboard = () => {
  const [restaurantData, setRestaurantData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [stats, setStats] = useState({
    totalOrders: 0,
    completedOrders: 0,
    pendingOrders: 0,
    todayOrders: 0,
    balance: 0,
  });
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (!user) {
        navigate('/restaurant/login');
        return;
      }

      const restaurantId = localStorage.getItem('restaurantId');
      if (!restaurantId) {
        navigate('/restaurant/login');
        return;
      }

      try {
        // جلب بيانات المطعم
        const restaurantDoc = await getDoc(doc(db, 'restaurants', restaurantId));
        if (!restaurantDoc.exists()) {
          console.error('لا يوجد مطعم بهذا المعرف');
          navigate('/restaurant/login');
          return;
        }

        const restaurant = { id: restaurantDoc.id, ...restaurantDoc.data() };
        setRestaurantData(restaurant);
        
        // جلب إحصائيات الطلبات
        await fetchOrderStats(restaurantId);
      } catch (error) {
        console.error('خطأ في جلب بيانات المطعم:', error);
      } finally {
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  const fetchOrderStats = async (restaurantId) => {
    try {
      // جلب جميع الطلبات
      const ordersRef = collection(db, 'orders');
      const q = query(ordersRef, where('restaurantId', '==', restaurantId));
      const querySnapshot = await getDocs(q);
      const orders = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      // حساب الإحصائيات
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      const totalOrders = orders.length;
      const completedOrders = orders.filter(order => order.status === 'تم التسليم ✅').length;
      const pendingOrders = orders.filter(order => order.status !== 'تم التسليم ✅' && order.status !== 'ملغي ❌').length;
      const todayOrders = orders.filter(order => {
        const orderDate = order.createdAt ? new Date(order.createdAt.toDate()) : null;
        return orderDate && orderDate >= today;
      }).length;

      // جلب رصيد المطعم
      const restaurantDoc = await getDoc(doc(db, 'restaurants', restaurantId));
      const balance = restaurantDoc.data().balance || 0;

      setStats({
        totalOrders,
        completedOrders,
        pendingOrders,
        todayOrders,
        balance
      });
    } catch (error) {
      console.error('خطأ في جلب إحصائيات الطلبات:', error);
    }
  };

  if (loading) {
    return (
      <div className="restaurant-loading">
        <div className="restaurant-spinner"></div>
        <p>جاري تحميل البيانات...</p>
      </div>
    );
  }

  return (
    <div className="restaurant-dashboard">
      <RestaurantSidebar />
      <div className="restaurant-dashboard-content">
        <RestaurantHeader restaurantName={restaurantData?.name || 'المطعم'} />
        
        <div className="restaurant-dashboard-welcome">
          <h1>أهلاً بك، {restaurantData?.name || 'المطعم'}!</h1>
          <p>هذه هي لوحة التحكم الخاصة بمطعمك. يمكنك إدارة طلباتك وتتبع أدائك هنا.</p>
        </div>

        <div className="restaurant-dashboard-stats">
          <div className="stat-card">
            <div className="stat-icon orders-icon">📦</div>
            <div className="stat-content">
              <h3>إجمالي الطلبات</h3>
              <p className="stat-number">{stats.totalOrders}</p>
            </div>
          </div>

          <div className="stat-card">
            <div className="stat-icon completed-icon">✅</div>
            <div className="stat-content">
              <h3>الطلبات المكتملة</h3>
              <p className="stat-number">{stats.completedOrders}</p>
            </div>
          </div>

          <div className="stat-card">
            <div className="stat-icon pending-icon">🕒</div>
            <div className="stat-content">
              <h3>الطلبات قيد التنفيذ</h3>
              <p className="stat-number">{stats.pendingOrders}</p>
            </div>
          </div>

          <div className="stat-card">
            <div className="stat-icon today-icon">📅</div>
            <div className="stat-content">
              <h3>طلبات اليوم</h3>
              <p className="stat-number">{stats.todayOrders}</p>
            </div>
          </div>
        </div>

        <div className="restaurant-dashboard-balance">
          <div className="balance-card">
            <h3>رصيد المحفظة</h3>
            <p className="balance-amount">{stats.balance.toFixed(3)} د.ك</p>
            <button className="balance-recharge-btn" onClick={() => navigate('/restaurant/wallet')}>
              شحن الرصيد
            </button>
          </div>
        </div>

        <div className="restaurant-dashboard-actions">
          <div className="action-card" onClick={() => navigate('/restaurant/orders/new')}>
            <div className="action-icon">🚚</div>
            <h3>إرسال طلب جديد</h3>
            <p>أرسل طلباتك للسائقين لتوصيلها للعملاء</p>
          </div>

          <div className="action-card" onClick={() => navigate('/restaurant/orders')}>
            <div className="action-icon">📋</div>
            <h3>إدارة الطلبات</h3>
            <p>عرض وإدارة جميع طلباتك</p>
          </div>

          <div className="action-card" onClick={() => navigate('/restaurant/reports')}>
            <div className="action-icon">📊</div>
            <h3>التقارير</h3>
            <p>عرض تقارير المبيعات والإحصائيات</p>
          </div>

          <div className="action-card" onClick={() => navigate('/restaurant/wallet')}>
            <div className="action-icon">💰</div>
            <h3>المحفظة</h3>
            <p>إدارة رصيدك وعرض المعاملات المالية</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RestaurantDashboard;