// src/restaurant/pages/RestaurantNotifications.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, query, where, getDocs, updateDoc, doc, orderBy } from 'firebase/firestore';
import { db, auth } from '../../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import RestaurantSidebar from '../../components/RestaurantSidebar';
import RestaurantHeader from '../../components/RestaurantHeader';
import './RestaurantNotifications.css';

const RestaurantNotifications = () => {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState('all'); // 'all', 'unread', 'read'
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (!user) {
        navigate('/restaurant/login');
        return;
      }

      const restaurantId = localStorage.getItem('restaurantId');
      if (!restaurantId) {
        navigate('/restaurant/login');
        return;
      }

      await fetchNotifications(restaurantId);
    });

    return () => unsubscribe();
  }, [navigate]);

  const fetchNotifications = async (restaurantId) => {
    try {
      setLoading(true);
      
      const notificationsRef = collection(db, 'notifications');
      const q = query(
        notificationsRef,
        where('recipientId', '==', restaurantId),
        where('recipientType', '==', 'restaurant'),
        orderBy('timestamp', 'desc')
      );
      
      const querySnapshot = await getDocs(q);
      const notificationsData = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        formattedDate: doc.data().timestamp ? formatTimestamp(doc.data().timestamp) : 'غير متاح'
      }));
      
      setNotifications(notificationsData);
    } catch (error) {
      console.error('خطأ في جلب الإشعارات:', error);
    } finally {
      setLoading(false);
    }
  };

  const formatTimestamp = (timestamp) => {
    if (!timestamp || !timestamp.toDate) return 'غير متاح';
    
    const date = timestamp.toDate();
    const now = new Date();
    const diffMs = now - date;
    const diffMins = Math.round(diffMs / 60000);
    
    if (diffMins < 1) return 'الآن';
    if (diffMins < 60) return `منذ ${diffMins} دقيقة`;
    
    const diffHours = Math.floor(diffMins / 60);
    if (diffHours < 24) return `منذ ${diffHours} ساعة`;
    
    const diffDays = Math.floor(diffHours / 24);
    if (diffDays < 30) return `منذ ${diffDays} يوم`;
    
    return date.toLocaleDateString('ar-EG', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  const markAsRead = async (notificationId) => {
    try {
      await updateDoc(doc(db, 'notifications', notificationId), { isRead: true });
      
      setNotifications(notifications.map(notification => 
        notification.id === notificationId 
          ? { ...notification, isRead: true } 
          : notification
      ));
    } catch (error) {
      console.error('خطأ في تحديث حالة الإشعار:', error);
    }
  };

  const markAllAsRead = async () => {
    try {
      const unreadNotifications = notifications.filter(notification => !notification.isRead);
      
      if (unreadNotifications.length === 0) return;
      
      // تحديث كل الإشعارات غير المقروءة
      const updatePromises = unreadNotifications.map(notification => 
        updateDoc(doc(db, 'notifications', notification.id), { isRead: true })
      );
      
      await Promise.all(updatePromises);
      
      // تحديث الحالة المحلية
      setNotifications(notifications.map(notification => ({ ...notification, isRead: true })));
    } catch (error) {
      console.error('خطأ في تحديث حالة الإشعارات:', error);
    }
  };

  const filteredNotifications = notifications.filter(notification => {
    if (filter === 'unread') return !notification.isRead;
    if (filter === 'read') return notification.isRead;
    return true;
  });

  if (loading) {
    return (
      <div className="restaurant-loading">
        <div className="restaurant-spinner"></div>
        <p>جاري تحميل البيانات...</p>
      </div>
    );
  }

  return (
    <div className="restaurant-notifications-page">
      <RestaurantSidebar />
      <div className="restaurant-notifications-content">
        <RestaurantHeader restaurantName={localStorage.getItem('restaurantName') || 'المطعم'} />
        
        <div className="notifications-container">
          <div className="notifications-header">
            <h1>الإشعارات</h1>
            <div className="notifications-actions">
              <button 
                className="mark-all-btn" 
                onClick={markAllAsRead}
                disabled={!notifications.some(notification => !notification.isRead)}
              >
                تعليم الكل كمقروء
              </button>
            </div>
          </div>
          
          <div className="notifications-filters">
            <button 
              className={`filter-btn ${filter === 'all' ? 'active' : ''}`} 
              onClick={() => setFilter('all')}
            >
              الكل
            </button>
            <button 
              className={`filter-btn ${filter === 'unread' ? 'active' : ''}`} 
              onClick={() => setFilter('unread')}
            >
              غير مقروءة
            </button>
            <button 
              className={`filter-btn ${filter === 'read' ? 'active' : ''}`} 
              onClick={() => setFilter('read')}
            >
              مقروءة
            </button>
          </div>
          
          {filteredNotifications.length === 0 ? (
            <div className="no-notifications">
              <p>لا توجد إشعارات{filter !== 'all' ? ' في هذه الفئة' : ''}</p>
            </div>
          ) : (
            <div className="notifications-list">
              {filteredNotifications.map(notification => (
                <div 
                  key={notification.id} 
                  className={`notification-item ${!notification.isRead ? 'unread' : ''}`}
                >
                  <div className="notification-content">
                    <h3>{notification.message}</h3>
                    <p className="notification-time">{notification.formattedDate}</p>
                  </div>
                  {!notification.isRead && (
                    <button 
                      className="mark-read-btn" 
                      onClick={() => markAsRead(notification.id)}
                    >
                      تعليم كمقروء
                    </button>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RestaurantNotifications;