import React, { useState, useEffect } from "react";
import { db } from "../firebase";
import { collection, getDocs } from "firebase/firestore";
import { GoogleMap, Marker } from "@react-google-maps/api";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { useGoogleMaps } from "../components/GoogleMapsProvider";

const AdminOrders = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const { isLoaded } = useGoogleMaps();

  useEffect(() => {
    // جلب جميع الطلبات من قاعدة البيانات
    const fetchOrders = async () => {
      try {
        console.log("📌 جاري جلب الطلبات...");
        const querySnapshot = await getDocs(collection(db, "orders"));
        const data = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setOrders(data);
      } catch (error) {
        console.error("❌ خطأ في جلب الطلبات:", error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, []);

  // مكون عرض حالة التحميل
  const LoadingSpinner = () => (
    <div style={{
      display: "flex", 
      justifyContent: "center", 
      alignItems: "center", 
      height: "200px"
    }}>
      <div style={{
        width: "50px",
        height: "50px",
        border: "5px solid #f3f3f3",
        borderTop: "5px solid #3498db",
        borderRadius: "50%",
        animation: "spin 1s linear infinite"
      }} />
      <style jsx>{`
        @keyframes spin {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
        }
      `}</style>
    </div>
  );

  return (
    <div>
      <Header />
      <div style={{ display: "flex", justifyContent: "space-between", marginTop: "50px" }}>

        {/* خريطة الطلبات */}
        <div style={{ width: "40%", height: "500px" }}>
          {isLoaded ? (
            <GoogleMap 
              mapContainerStyle={{ width: "100%", height: "100%" }} 
              center={{ lat: 29.3759, lng: 47.9774 }} 
              zoom={12}
            >
              {orders.map((order) => (
                <Marker
                  key={order.id}
                  position={order.driverLocation || { lat: 29.3759, lng: 47.9774 }}
                  label={order.status === "تم التسليم ✅" ? "✅" : order.status === "تم الاستلام 🚗" ? "🚗" : "🚀"}
                  onClick={() => setSelectedOrder(order)}
                />
              ))}
            </GoogleMap>
          ) : (
            <div style={{ 
              width: "100%", 
              height: "100%", 
              display: "flex", 
              justifyContent: "center", 
              alignItems: "center",
              backgroundColor: "#f8f9fa",
              borderRadius: "5px" 
            }}>
              <p>جاري تحميل الخريطة...</p>
            </div>
          )}
        </div>

        {/* عرض الطلبات */}
        <div style={{ width: "55%" }}>
          <h2>إدارة الطلبات</h2>
          {loading ? (
            <LoadingSpinner />
          ) : orders.length === 0 ? (
            <div style={{ 
              padding: "20px", 
              backgroundColor: "#f8f9fa", 
              borderRadius: "5px",
              textAlign: "center" 
            }}>
              <p>⚠️ لا توجد طلبات متاحة.</p>
            </div>
          ) : (
            <table border="1" width="100%" style={{ marginTop: "20px", textAlign: "center" }}>
              <thead>
                <tr>
                  <th>رقم الطلب</th>
                  <th>اسم العميل</th>
                  <th>الهاتف</th>
                  <th>العنوان</th>
                  <th>السائق</th>
                  <th>حالة الطلب</th>
                  <th>الإجراءات</th>
                </tr>
              </thead>
              <tbody>
                {orders.map((order, index) => (
                  <tr key={order.id}>
                    <td>{index + 1}</td>
                    <td>{order.customerName}</td>
                    <td>{order.phone}</td>
                    <td>{order.address}</td>
                    <td>{order.driverName || "غير مخصص"}</td>
                    <td>{order.status}</td>
                    <td>
                      <button 
                        onClick={() => setSelectedOrder(order)}
                        style={{
                          backgroundColor: "#007bff",
                          color: "white",
                          border: "none",
                          borderRadius: "4px",
                          padding: "5px 10px",
                          cursor: "pointer"
                        }}
                      >
                        📍 تتبع الطلب
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}

          {selectedOrder && (
            <div style={{ marginTop: "20px", padding: "15px", border: "1px solid gray", background: "#f9f9f9", borderRadius: "5px" }}>
              <h3>📌 تتبع الطلب رقم: {selectedOrder.id}</h3>
              <p><b>اسم العميل:</b> {selectedOrder.customerName}</p>
              <p><b>الهاتف:</b> {selectedOrder.phone}</p>
              <p><b>العنوان:</b> {selectedOrder.address}</p>
              <p><b>السائق:</b> {selectedOrder.driverName || "غير مخصص"}</p>
              <p><b>حالة الطلب:</b> {selectedOrder.status}</p>
              <button 
                onClick={() => setSelectedOrder(null)}
                style={{
                  backgroundColor: "#6c757d",
                  color: "white",
                  border: "none",
                  borderRadius: "4px",
                  padding: "5px 10px",
                  cursor: "pointer"
                }}
              >
                إغلاق
              </button>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AdminOrders;