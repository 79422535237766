// src/restaurant/pages/Wallet.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, query, where, getDocs, doc, getDoc, updateDoc, addDoc, orderBy, serverTimestamp } from 'firebase/firestore';
import { db, auth } from '../../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import RestaurantSidebar from '../../components/RestaurantSidebar';
import RestaurantHeader from '../../components/RestaurantHeader';
import './Wallet.css';

const Wallet = () => {
  const [restaurantData, setRestaurantData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [transactions, setTransactions] = useState([]);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState('visa');
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [processingPayment, setProcessingPayment] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const navigate = useNavigate();

  // حزم الشحن
  const packages = [
    { id: 1, name: 'باقة صغيرة', amount: 20, description: 'رصيد بقيمة 20 دينار كويتي' },
    { id: 2, name: 'باقة متوسطة', amount: 50, description: 'رصيد بقيمة 50 دينار كويتي' },
    { id: 3, name: 'باقة كبيرة', amount: 100, description: 'رصيد بقيمة 100 دينار كويتي' }
  ];

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (!user) {
        navigate('/restaurant/login');
        return;
      }

      const restaurantId = localStorage.getItem('restaurantId');
      if (!restaurantId) {
        navigate('/restaurant/login');
        return;
      }

      try {
        // جلب بيانات المطعم
        const restaurantDoc = await getDoc(doc(db, 'restaurants', restaurantId));
        if (!restaurantDoc.exists()) {
          console.error('لا يوجد مطعم بهذا المعرف');
          navigate('/restaurant/login');
          return;
        }

        const restaurant = { id: restaurantDoc.id, ...restaurantDoc.data() };
        setRestaurantData(restaurant);
        
        // جلب المعاملات المالية
        await fetchTransactions(restaurantId);
      } catch (error) {
        console.error('خطأ في جلب بيانات المطعم:', error);
      } finally {
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  const fetchTransactions = async (restaurantId) => {
    try {
      const transactionsRef = collection(db, 'transactions');
      const q = query(
        transactionsRef,
        where('restaurantId', '==', restaurantId),
        orderBy('timestamp', 'desc')
      );
      const querySnapshot = await getDocs(q);
      const transactionsData = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        formattedDate: doc.data().timestamp ? formatTimestamp(doc.data().timestamp) : ''
      }));

      setTransactions(transactionsData);
    } catch (error) {
      console.error('خطأ في جلب المعاملات المالية:', error);
    }
  };

  const formatTimestamp = (timestamp) => {
    try {
      const date = timestamp.toDate();
      return date.toLocaleDateString('ar-EG', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      });
    } catch (error) {
      return 'تاريخ غير صالح';
    }
  };

  const handleSelectPackage = (pkg) => {
    setSelectedPackage(pkg);
    setShowPaymentModal(true);
  };

  const handlePaymentMethodChange = (e) => {
    setPaymentMethod(e.target.value);
  };

  const handleSubmitPayment = async (e) => {
    e.preventDefault();
    
    if (!selectedPackage) {
      setError('الرجاء اختيار باقة');
      return;
    }

    setProcessingPayment(true);
    setError('');

    try {
      // محاكاة عملية الدفع - في النظام الحقيقي سيتم الاتصال ببوابة الدفع
      await new Promise(resolve => setTimeout(resolve, 2000));
      
      const restaurantId = restaurantData.id;
      const currentBalance = restaurantData.balance || 0;
      const newBalance = currentBalance + selectedPackage.amount;
      
      // تحديث رصيد المطعم
      await updateDoc(doc(db, 'restaurants', restaurantId), {
        balance: newBalance
      });
      
      // إضافة معاملة مالية
      await addDoc(collection(db, 'transactions'), {
        restaurantId,
        type: 'شحن',
        amount: selectedPackage.amount,
        description: `شحن رصيد: ${selectedPackage.name}`,
        paymentMethod,
        timestamp: serverTimestamp()
      });
      
      // تحديث البيانات المحلية
      setRestaurantData({
        ...restaurantData,
        balance: newBalance
      });
      
      // إعادة تحميل المعاملات
      await fetchTransactions(restaurantId);
      
      setSuccess(`تم شحن رصيدك بمبلغ ${selectedPackage.amount} د.ك بنجاح!`);
      setShowPaymentModal(false);
      setSelectedPackage(null);
    } catch (error) {
      console.error('خطأ في عملية الدفع:', error);
      setError('حدث خطأ أثناء معالجة عملية الدفع. يرجى المحاولة مرة أخرى.');
    } finally {
      setProcessingPayment(false);
    }
  };

  const closePaymentModal = () => {
    setShowPaymentModal(false);
    setSelectedPackage(null);
    setError('');
  };

  if (loading) {
    return (
      <div className="restaurant-loading">
        <div className="restaurant-spinner"></div>
        <p>جاري تحميل البيانات...</p>
      </div>
    );
  }

  return (
    <div className="restaurant-wallet-page">
      <RestaurantSidebar />
      <div className="restaurant-wallet-content">
        <RestaurantHeader restaurantName={restaurantData?.name || 'المطعم'} />
        
        <div className="wallet-container">
          <h1 className="page-title">المحفظة</h1>
          
          {success && <div className="success-message">{success}</div>}
          
          <div className="wallet-grid">
            <div className="wallet-balance-section">
              <div className="wallet-balance-card">
                <h2>رصيدك الحالي</h2>
                <div className="balance-amount">
                  <span className="currency">د.ك</span>
                  <span className="amount">{restaurantData?.balance?.toFixed(3) || '0.000'}</span>
                </div>
                <p className="balance-info">
                  يتم خصم رسوم التوصيل تلقائياً من رصيدك عند إرسال طلب.
                  <br />
                  أول 20 كم: 1.350 د.ك، وبعد ذلك: 0.100 د.ك لكل كم إضافي.
                </p>
              </div>
              
              <div className="recharge-section">
                <h2>شحن الرصيد</h2>
                <p>اختر باقة للشحن:</p>
                
                <div className="packages-grid">
                  {packages.map(pkg => (
                    <div
                      key={pkg.id}
                      className="package-card"
                      onClick={() => handleSelectPackage(pkg)}
                    >
                      <h3>{pkg.name}</h3>
                      <div className="package-amount">{pkg.amount} د.ك</div>
                      <p>{pkg.description}</p>
                      <button className="recharge-btn">شحن الرصيد</button>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            
            <div className="transactions-section">
              <h2>سجل المعاملات</h2>
              
              {transactions.length === 0 ? (
                <div className="no-transactions">
                  <p>لا توجد معاملات سابقة</p>
                </div>
              ) : (
                <div className="transactions-list">
                  {transactions.map(transaction => (
                    <div
                      key={transaction.id}
                      className={`transaction-item ${transaction.type === 'شحن' ? 'deposit' : 'deduction'}`}
                    >
                      <div className="transaction-details">
                        <div className="transaction-info">
                          <h3 className="transaction-description">{transaction.description}</h3>
                          <p className="transaction-date">{transaction.formattedDate}</p>
                        </div>
                        <div className="transaction-amount">
                          {transaction.type === 'شحن' ? '+' : '-'} {transaction.amount.toFixed(3)} د.ك
                        </div>
                      </div>
                      <div className="transaction-type">
                        {transaction.type === 'شحن' ? 'إيداع' : 'خصم'}
                        {transaction.paymentMethod && (
                          <span className="transaction-method">
                            ({transaction.paymentMethod === 'visa' ? 'فيزا' : 
                               transaction.paymentMethod === 'paypal' ? 'باي بال' : 
                               transaction.paymentMethod})
                          </span>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      
      {/* نافذة الدفع */}
      {showPaymentModal && (
        <div className="payment-modal-overlay">
          <div className="payment-modal">
            <div className="payment-modal-header">
              <h2>شحن الرصيد</h2>
              <button className="close-modal-btn" onClick={closePaymentModal}>&times;</button>
            </div>
            
            {error && <div className="error-message">{error}</div>}
            
            <div className="payment-details">
              <p>الباقة: <strong>{selectedPackage?.name}</strong></p>
              <p>المبلغ: <strong>{selectedPackage?.amount} د.ك</strong></p>
            </div>
            
            <form onSubmit={handleSubmitPayment} className="payment-form">
              <div className="form-group">
                <label>اختر طريقة الدفع:</label>
                <div className="payment-methods">
                  <label className="payment-method">
                    <input
                      type="radio"
                      name="paymentMethod"
                      value="visa"
                      checked={paymentMethod === 'visa'}
                      onChange={handlePaymentMethodChange}
                    />
                    <div className="payment-method-content">
                      <span className="payment-method-icon">💳</span>
                      <span className="payment-method-name">فيزا / ماستركارد</span>
                    </div>
                  </label>
                  
                  <label className="payment-method">
                    <input
                      type="radio"
                      name="paymentMethod"
                      value="paypal"
                      checked={paymentMethod === 'paypal'}
                      onChange={handlePaymentMethodChange}
                    />
                    <div className="payment-method-content">
                      <span className="payment-method-icon">🅿️</span>
                      <span className="payment-method-name">باي بال</span>
                    </div>
                  </label>
                </div>
              </div>
              
              {paymentMethod === 'visa' && (
                <div className="credit-card-form">
                  <div className="form-group">
                    <label htmlFor="cardNumber">رقم البطاقة</label>
                    <input
                      type="text"
                      id="cardNumber"
                      placeholder="0000 0000 0000 0000"
                      disabled={processingPayment}
                      required
                    />
                  </div>
                  
                  <div className="form-row">
                    <div className="form-group">
                      <label htmlFor="expiryDate">تاريخ الانتهاء</label>
                      <input
                        type="text"
                        id="expiryDate"
                        placeholder="MM/YY"
                        disabled={processingPayment}
                        required
                      />
                    </div>
                    
                    <div className="form-group">
                      <label htmlFor="cvv">رمز الأمان (CVV)</label>
                      <input
                        type="text"
                        id="cvv"
                        placeholder="123"
                        disabled={processingPayment}
                        required
                      />
                    </div>
                  </div>
                  
                  <div className="form-group">
                    <label htmlFor="cardName">الاسم على البطاقة</label>
                    <input
                      type="text"
                      id="cardName"
                      placeholder="الاسم كما هو مكتوب على البطاقة"
                      disabled={processingPayment}
                      required
                    />
                  </div>
                </div>
              )}
              
              {paymentMethod === 'paypal' && (
                <div className="paypal-form">
                  <p>سيتم تحويلك إلى موقع باي بال لإتمام عملية الدفع</p>
                </div>
              )}
              
              <button
                type="submit"
                className="payment-submit-btn"
                disabled={processingPayment}
              >
                {processingPayment ? 'جاري معالجة الدفع...' : 'إتمام عملية الدفع'}
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Wallet;