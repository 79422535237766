import React, { useState, useEffect } from "react";
import { db } from "../firebase";
import { collection, getDocs, updateDoc, doc, query, orderBy, where } from "firebase/firestore";
import Header from "../components/Header";
import Footer from "../components/Footer";

const NotificationsPage = () => {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userType, setUserType] = useState("admin"); // يمكن تغييره حسب المستخدم الحالي (admin, restaurant, driver)

  useEffect(() => {
    // اختيار نوع المستخدم من localStorage (إذا كان مخزنًا)
    const savedUserRole = localStorage.getItem("userRole");
    if (savedUserRole) {
      setUserType(savedUserRole);
    }

    fetchNotifications();
  }, [userType]);

  // ✅ جلب الإشعارات من Firestore
  const fetchNotifications = async () => {
    try {
      setLoading(true);
      console.log("📌 جاري جلب الإشعارات...");
      
      // إنشاء استعلام يجلب الإشعارات المخصصة لنوع المستخدم الحالي
      const notificationsQuery = query(
        collection(db, "notifications"),
        where("recipientType", "==", userType),
        orderBy("timestamp", "desc")
      );
      
      const querySnapshot = await getDocs(notificationsQuery);

      if (querySnapshot.empty) {
        console.warn("⚠️ لا توجد إشعارات متاحة.");
        setNotifications([]);
      } else {
        const data = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          // تحويل الطابع الزمني إلى تنسيق قابل للقراءة
          formattedDate: doc.data().timestamp ? new Date(doc.data().timestamp.toDate()).toLocaleString('ar-EG') : "غير متوفر"
        }));
        
        console.log("📌 الإشعارات المسترجعة:", data);
        setNotifications(data);
      }
    } catch (error) {
      console.error("❌ خطأ في جلب الإشعارات:", error.message);
    } finally {
      setLoading(false);
    }
  };



  // ✅ تحديث حالة الإشعار (مقروء/غير مقروء)
  const markAsRead = async (notificationId) => {
    try {
      await updateDoc(doc(db, "notifications", notificationId), { isRead: true });
      setNotifications((prev) =>
        prev.map((notification) =>
          notification.id === notificationId ? { ...notification, isRead: true } : notification
        )
      );
    } catch (error) {
      console.error("❌ خطأ في تحديث حالة الإشعار:", error.message);
    }
  };

  // ✅ تبديل نوع المستخدم (للاختبار)
  const toggleUserType = () => {
    const nextType = userType === "admin" ? "restaurant" : userType === "restaurant" ? "driver" : "admin";
    setUserType(nextType);
  };

  return (
    <div>
      <Header />
      <div style={{ textAlign: "center", marginTop: "50px", padding: "0 20px" }}>
        <h2>الإشعارات</h2>
        
        {/* اختيار نوع المستخدم (للاختبار) */}
        <div style={{ marginBottom: "20px" }}>
          <button 
            onClick={toggleUserType}
            style={{ 
              backgroundColor: "#6c757d", 
              color: "white", 
              padding: "8px 15px", 
              border: "none", 
              borderRadius: "5px" 
            }}
          >
            تغيير نوع المستخدم: {userType === "admin" ? "مدير" : userType === "restaurant" ? "مطعم" : "سائق"}
          </button>
        </div>

        {/* عرض الإشعارات */}
        {loading ? (
          <div style={{ display: "flex", justifyContent: "center", padding: "20px" }}>
            <div style={{
              width: "50px",
              height: "50px",
              border: "5px solid #f3f3f3",
              borderTop: "5px solid #3498db",
              borderRadius: "50%",
              animation: "spin 1s linear infinite"
            }} />
            <style jsx>{`
              @keyframes spin {
                0% { transform: rotate(0deg); }
                100% { transform: rotate(360deg); }
              }
            `}</style>
          </div>
        ) : notifications.length === 0 ? (
          <div style={{ 
            padding: "20px", 
            backgroundColor: "#f8f9fa", 
            borderRadius: "5px",
            maxWidth: "600px",
            margin: "0 auto" 
          }}>
            <p>⚠️ لا توجد إشعارات حالياً</p>
          </div>
        ) : (
          <div style={{ maxWidth: "800px", margin: "0 auto" }}>
            {notifications.map((notification) => (
              <div
                key={notification.id}
                style={{
                  backgroundColor: notification.isRead ? "#f8f9fa" : "#e2f0ff",
                  padding: "15px",
                  margin: "10px 0",
                  borderRadius: "5px",
                  textAlign: "right",
                  boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center"
                }}
              >
                <button
                  onClick={() => markAsRead(notification.id)}
                  disabled={notification.isRead}
                  style={{
                    backgroundColor: notification.isRead ? "#6c757d" : "#007bff",
                    color: "white",
                    padding: "5px 10px",
                    border: "none",
                    borderRadius: "3px",
                    cursor: notification.isRead ? "default" : "pointer",
                    opacity: notification.isRead ? 0.5 : 1
                  }}
                >
                  {notification.isRead ? "مقروء" : "تعليم كمقروء"}
                </button>
                
                <div>
                  <div style={{ fontWeight: "bold", marginBottom: "5px" }}>
                    {notification.message}
                  </div>
                  <div style={{ fontSize: "12px", color: "#6c757d" }}>
                    {notification.formattedDate}
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default NotificationsPage;