import React from 'react';

const Footer = () => {
  return (
    <footer style={{
      position: 'fixed', bottom: 0, width: '100%', padding: '10px',
      backgroundColor: '#f8f8f8', borderTop: '1px solid #ddd', textAlign: 'center'
    }}>
      <p>&copy; 2025 AT Delivery. جميع الحقوق محفوظة.</p>
    </footer>
  );
};

export default Footer;
