// src/restaurant/pages/Settings.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db, auth } from '../../firebase';
import { onAuthStateChanged, updatePassword, EmailAuthProvider, reauthenticateWithCredential } from 'firebase/auth';
import RestaurantSidebar from '../../components/RestaurantSidebar';
import RestaurantHeader from '../../components/RestaurantHeader';
import './Settings.css';

const Settings = () => {
  const [restaurantData, setRestaurantData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [updatedData, setUpdatedData] = useState({});
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [tab, setTab] = useState('profile'); // 'profile', 'password', 'notifications'
  const [notificationSettings, setNotificationSettings] = useState({
    orderUpdates: true,
    driverUpdates: true,
    financialUpdates: true,
    marketingUpdates: false
  });
  
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (!user) {
        navigate('/restaurant/login');
        return;
      }

      const restaurantId = localStorage.getItem('restaurantId');
      if (!restaurantId) {
        navigate('/restaurant/login');
        return;
      }

      try {
        // جلب بيانات المطعم
        const restaurantDoc = await getDoc(doc(db, 'restaurants', restaurantId));
        if (!restaurantDoc.exists()) {
          console.error('لا يوجد مطعم بهذا المعرف');
          navigate('/restaurant/login');
          return;
        }

        const restaurant = { id: restaurantDoc.id, ...restaurantDoc.data() };
        setRestaurantData(restaurant);
        setUpdatedData({
          name: restaurant.name || '',
          phone: restaurant.phone || '',
          address: restaurant.address || '',
          area: restaurant.area || ''
        });
        
        // تحميل إعدادات الإشعارات إذا كانت موجودة
        if (restaurant.notificationSettings) {
          setNotificationSettings(restaurant.notificationSettings);
        }
      } catch (error) {
        console.error('خطأ في جلب بيانات المطعم:', error);
      } finally {
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  const handleProfileUpdate = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');
    
    try {
      setLoading(true);
      
      const restaurantId = restaurantData.id;
      await updateDoc(doc(db, 'restaurants', restaurantId), updatedData);
      
      // تحديث البيانات المحلية
      setRestaurantData({
        ...restaurantData,
        ...updatedData
      });
      
      // تحديث localStorage إذا تم تغيير الاسم
      if (updatedData.name !== restaurantData.name) {
        localStorage.setItem('restaurantName', updatedData.name);
      }
      
      setSuccess('تم تحديث بيانات المطعم بنجاح');
    } catch (error) {
      console.error('خطأ في تحديث بيانات المطعم:', error);
      setError('حدث خطأ في تحديث البيانات. يرجى المحاولة مرة أخرى.');
    } finally {
      setLoading(false);
    }
  };

  const handlePasswordChange = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');
    
    if (newPassword !== confirmPassword) {
      setError('كلمة المرور الجديدة وتأكيدها غير متطابقين');
      return;
    }
    
    if (newPassword.length < 6) {
      setError('يجب أن تكون كلمة المرور الجديدة 6 أحرف على الأقل');
      return;
    }
    
    try {
      setLoading(true);
      
      const user = auth.currentUser;
      
      // إعادة مصادقة المستخدم قبل تغيير كلمة المرور
      const credential = EmailAuthProvider.credential(user.email, currentPassword);
      await reauthenticateWithCredential(user, credential);
      
      // تغيير كلمة المرور
      await updatePassword(user, newPassword);
      
      setSuccess('تم تغيير كلمة المرور بنجاح');
      setCurrentPassword('');
      setNewPassword('');
      setConfirmPassword('');
    } catch (error) {
      console.error('خطأ في تغيير كلمة المرور:', error);
      if (error.code === 'auth/wrong-password') {
        setError('كلمة المرور الحالية غير صحيحة');
      } else {
        setError('حدث خطأ في تغيير كلمة المرور. يرجى المحاولة مرة أخرى.');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleNotificationSettingsUpdate = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');
    
    try {
      setLoading(true);
      
      const restaurantId = restaurantData.id;
      await updateDoc(doc(db, 'restaurants', restaurantId), {
        notificationSettings
      });
      
      // تحديث البيانات المحلية
      setRestaurantData({
        ...restaurantData,
        notificationSettings
      });
      
      setSuccess('تم تحديث إعدادات الإشعارات بنجاح');
    } catch (error) {
      console.error('خطأ في تحديث إعدادات الإشعارات:', error);
      setError('حدث خطأ في تحديث الإعدادات. يرجى المحاولة مرة أخرى.');
    } finally {
      setLoading(false);
    }
  };

  if (loading && !restaurantData) {
    return (
      <div className="restaurant-loading">
        <div className="restaurant-spinner"></div>
        <p>جاري تحميل البيانات...</p>
      </div>
    );
  }

  return (
    <div className="restaurant-settings-page">
      <RestaurantSidebar />
      <div className="restaurant-settings-content">
        <RestaurantHeader restaurantName={restaurantData?.name || 'المطعم'} />
        
        <div className="settings-container">
          <h1 className="page-title">الإعدادات</h1>
          
          <div className="settings-tabs">
            <button 
              className={`settings-tab ${tab === 'profile' ? 'active' : ''}`}
              onClick={() => setTab('profile')}
            >
              الملف الشخصي
            </button>
            <button 
              className={`settings-tab ${tab === 'password' ? 'active' : ''}`}
              onClick={() => setTab('password')}
            >
              كلمة المرور
            </button>
            <button 
              className={`settings-tab ${tab === 'notifications' ? 'active' : ''}`}
              onClick={() => setTab('notifications')}
            >
              الإشعارات
            </button>
          </div>
          
          {error && <div className="error-message">{error}</div>}
          {success && <div className="success-message">{success}</div>}
          
          {tab === 'profile' && (
            <div className="settings-form-container">
              <h2>بيانات المطعم</h2>
              <form onSubmit={handleProfileUpdate} className="settings-form">
                <div className="form-group">
                  <label htmlFor="name">اسم المطعم</label>
                  <input 
                    type="text" 
                    id="name" 
                    value={updatedData.name} 
                    onChange={(e) => setUpdatedData({...updatedData, name: e.target.value})}
                    required
                  />
                </div>
                
                <div className="form-group">
                  <label htmlFor="email">البريد الإلكتروني</label>
                  <input 
                    type="email" 
                    id="email" 
                    value={restaurantData?.email || ''} 
                    disabled 
                  />
                  <small>* لا يمكن تغيير البريد الإلكتروني</small>
                </div>
                
                <div className="form-group">
                  <label htmlFor="phone">رقم الهاتف</label>
                  <input 
                    type="tel" 
                    id="phone" 
                    value={updatedData.phone} 
                    onChange={(e) => setUpdatedData({...updatedData, phone: e.target.value})}
                    required
                  />
                </div>
                
                <div className="form-group">
                  <label htmlFor="area">المنطقة</label>
                  <input 
                    type="text" 
                    id="area" 
                    value={updatedData.area} 
                    onChange={(e) => setUpdatedData({...updatedData, area: e.target.value})}
                    required
                  />
                </div>
                
                <div className="form-group">
                  <label htmlFor="address">العنوان</label>
                  <textarea 
                    id="address" 
                    value={updatedData.address} 
                    onChange={(e) => setUpdatedData({...updatedData, address: e.target.value})}
                    required
                  />
                </div>
                
                <button 
                  type="submit" 
                  className="save-btn" 
                  disabled={loading}
                >
                  {loading ? 'جاري الحفظ...' : 'حفظ التغييرات'}
                </button>
              </form>
            </div>
          )}
          
          {tab === 'password' && (
            <div className="settings-form-container">
              <h2>تغيير كلمة المرور</h2>
              <form onSubmit={handlePasswordChange} className="settings-form">
                <div className="form-group">
                  <label htmlFor="currentPassword">كلمة المرور الحالية</label>
                  <input 
                    type="password" 
                    id="currentPassword" 
                    value={currentPassword} 
                    onChange={(e) => setCurrentPassword(e.target.value)}
                    required
                  />
                </div>
                
                <div className="form-group">
                  <label htmlFor="newPassword">كلمة المرور الجديدة</label>
                  <input 
                    type="password" 
                    id="newPassword" 
                    value={newPassword} 
                    onChange={(e) => setNewPassword(e.target.value)}
                    required
                    minLength={6}
                  />
                </div>
                
                <div className="form-group">
                  <label htmlFor="confirmPassword">تأكيد كلمة المرور الجديدة</label>
                  <input 
                    type="password" 
                    id="confirmPassword" 
                    value={confirmPassword} 
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                    minLength={6}
                  />
                </div>
                
                <button 
                  type="submit" 
                  className="save-btn" 
                  disabled={loading}
                >
                  {loading ? 'جاري الحفظ...' : 'تغيير كلمة المرور'}
                </button>
              </form>
            </div>
          )}
          
          {tab === 'notifications' && (
            <div className="settings-form-container">
              <h2>إعدادات الإشعارات</h2>
              <form onSubmit={handleNotificationSettingsUpdate} className="settings-form">
                <div className="notification-options">
                  <div className="notification-option">
                    <input 
                      type="checkbox" 
                      id="orderUpdates" 
                      checked={notificationSettings.orderUpdates} 
                      onChange={(e) => setNotificationSettings({...notificationSettings, orderUpdates: e.target.checked})}
                    />
                    <label htmlFor="orderUpdates">تحديثات الطلبات</label>
                    <p className="option-description">الحصول على إشعارات عند تغيير حالة الطلبات</p>
                  </div>
                  
                  <div className="notification-option">
                    <input 
                      type="checkbox" 
                      id="driverUpdates" 
                      checked={notificationSettings.driverUpdates} 
                      onChange={(e) => setNotificationSettings({...notificationSettings, driverUpdates: e.target.checked})}
                    />
                    <label htmlFor="driverUpdates">تحديثات السائقين</label>
                    <p className="option-description">الحصول على إشعارات عند تغيير حالة السائقين أو موقعهم</p>
                  </div>
                  
                  <div className="notification-option">
                    <input 
                      type="checkbox" 
                      id="financialUpdates" 
                      checked={notificationSettings.financialUpdates} 
                      onChange={(e) => setNotificationSettings({...notificationSettings, financialUpdates: e.target.checked})}
                    />
                    <label htmlFor="financialUpdates">التحديثات المالية</label>
                    <p className="option-description">الحصول على إشعارات عند إجراء معاملات مالية</p>
                  </div>
                  
                  <div className="notification-option">
                    <input 
                      type="checkbox" 
                      id="marketingUpdates" 
                      checked={notificationSettings.marketingUpdates} 
                      onChange={(e) => setNotificationSettings({...notificationSettings, marketingUpdates: e.target.checked})}
                    />
                    <label htmlFor="marketingUpdates">تحديثات تسويقية</label>
                    <p className="option-description">الحصول على إشعارات حول العروض والتحديثات الجديدة</p>
                  </div>
                </div>
                
                <button 
                  type="submit" 
                  className="save-btn" 
                  disabled={loading}
                >
                  {loading ? 'جاري الحفظ...' : 'حفظ الإعدادات'}
                </button>
              </form>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Settings;