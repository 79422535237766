import React, { useState, useEffect } from "react";
import { db } from "../firebase";
import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  doc,
} from "firebase/firestore";
import Header from "../components/Header";
import Footer from "../components/Footer";

const OrdersManagement = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [newOrder, setNewOrder] = useState({
    restaurantId: "",
    customerName: "",
    customerPhone: "",
    customerAddress: "",
    orderAmount: 0,
    paymentMethod: "نقدي",
    status: "في انتظار السائق",
  });

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        console.log("📌 جاري جلب بيانات الطلبات...");
        const querySnapshot = await getDocs(collection(db, "orders"));

        if (querySnapshot.empty) {
          console.warn("⚠️ لا توجد طلبات متاحة في Firestore.");
          setOrders([]);
        } else {
          const data = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          console.log("📌 الطلبات المسترجعة:", data);
          setOrders(data);
        }
      } catch (error) {
        console.error("❌ خطأ في جلب بيانات الطلبات:", error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, []);

  // ✅ 1️⃣ إضافة طلب جديد
  const addOrder = async () => {
    if (!newOrder.restaurantId || !newOrder.customerName || !newOrder.customerPhone || !newOrder.customerAddress) {
      alert("❌ يجب ملء جميع الحقول!");
      return;
    }

    try {
      const docRef = await addDoc(collection(db, "orders"), newOrder);
      setOrders([...orders, { id: docRef.id, ...newOrder }]);
      alert("✅ تم إضافة الطلب بنجاح!");
      setNewOrder({
        restaurantId: "",
        customerName: "",
        customerPhone: "",
        customerAddress: "",
        orderAmount: 0,
        paymentMethod: "نقدي",
        status: "في انتظار السائق",
      });
    } catch (error) {
      console.error("❌ خطأ في إضافة الطلب:", error.message);
    }
  };


  // ✅ 3️⃣ تحديث حالة الطلب
  const updateOrderStatus = async (orderId, newStatus) => {
    try {
      await updateDoc(doc(db, "orders", orderId), { status: newStatus });
      setOrders((prev) =>
        prev.map((o) => (o.id === orderId ? { ...o, status: newStatus } : o))
      );
      alert(`✅ تم تحديث حالة الطلب إلى ${newStatus}!`);
    } catch (error) {
      console.error("❌ خطأ في تحديث حالة الطلب:", error.message);
    }
  };

  return (
    <div>
      <Header />
      <div style={{ textAlign: "center", marginTop: "50px" }}>
        <h2>إدارة الطلبات</h2>
        
        {/* ✅ فورم إضافة طلب جديد */}
        <div style={{ border: "1px solid gray", padding: "15px", width: "50%", margin: "auto", textAlign: "left", background: "#f9f9f9", borderRadius: "5px" }}>
          <h3>إضافة طلب جديد</h3>
          <input type="text" placeholder="معرف المطعم" value={newOrder.restaurantId} onChange={(e) => setNewOrder({ ...newOrder, restaurantId: e.target.value })} />
          <input type="text" placeholder="اسم العميل" value={newOrder.customerName} onChange={(e) => setNewOrder({ ...newOrder, customerName: e.target.value })} />
          <input type="text" placeholder="رقم هاتف العميل" value={newOrder.customerPhone} onChange={(e) => setNewOrder({ ...newOrder, customerPhone: e.target.value })} />
          <input type="text" placeholder="عنوان العميل" value={newOrder.customerAddress} onChange={(e) => setNewOrder({ ...newOrder, customerAddress: e.target.value })} />
          <input type="number" placeholder="المبلغ" value={newOrder.orderAmount} onChange={(e) => setNewOrder({ ...newOrder, orderAmount: parseFloat(e.target.value) })} />
          <select value={newOrder.paymentMethod} onChange={(e) => setNewOrder({ ...newOrder, paymentMethod: e.target.value })}>
            <option value="نقدي">نقدي</option>
            <option value="KNet">KNet</option>
            <option value="بطاقة">بطاقة</option>
          </select>
          <button onClick={addOrder}>إضافة الطلب</button>
        </div>

        {/* ✅ عرض الطلبات */}
        {loading ? (
          <p>⏳ جاري تحميل البيانات...</p>
        ) : orders.length === 0 ? (
          <p>⚠️ لا توجد طلبات متاحة.</p>
        ) : (
          <table border="1" width="80%" style={{ margin: "20px auto", textAlign: "center" }}>
            <thead>
              <tr>
                <th>رقم الطلب</th>
                <th>اسم العميل</th>
                <th>حالة الطلب</th>
                <th>المبلغ</th>
                <th>إجراءات</th>
              </tr>
            </thead>
            <tbody>
              {orders.map((order, index) => (
                <tr key={order.id}>
                  <td>{index + 1}</td>
                  <td>{order.customerName}</td>
                  <td>{order.status}</td>
                  <td>{order.orderAmount} دينار</td>
                  <td>
                    <button onClick={() => updateOrderStatus(order.id, "مكتمل")}>✅ إنهاء الطلب</button>
                    <button onClick={() => updateOrderStatus(order.id, "ملغي")}>❌ إلغاء الطلب</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default OrdersManagement;
