// src/components/ErrorBoundary.js
import React from "react";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ errorInfo });
    console.error("خطأ في التطبيق:", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div style={{ 
          padding: "20px", 
          margin: "20px", 
          borderRadius: "5px", 
          backgroundColor: "#ffebee", 
          color: "#c62828",
          textAlign: "center" 
        }}>
          <h2>حدث خطأ في التطبيق</h2>
          <p>نأسف على هذا العطل. يرجى تحديث الصفحة أو المحاولة لاحقاً.</p>
          <button 
            onClick={() => window.location.reload()} 
            style={{ 
              padding: "10px 20px", 
              backgroundColor: "#c62828", 
              color: "white", 
              border: "none", 
              borderRadius: "5px", 
              cursor: "pointer" 
            }}
          >
            تحديث الصفحة
          </button>
          
          <details style={{ marginTop: "20px", textAlign: "left" }}>
            <summary>تفاصيل الخطأ</summary>
            <p>{this.state.error && this.state.error.toString()}</p>
            <div>
              {this.state.errorInfo && this.state.errorInfo.componentStack.toString()}
            </div>
          </details>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;