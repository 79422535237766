import React, { useState, useEffect } from "react";
import { 
  collection, 
  query, 
  where, 
  getDocs, 
  doc, 
  updateDoc 
} from "firebase/firestore";
import { db } from "../../firebase";
import { GoogleMap, Marker } from "@react-google-maps/api";
import { getAuth } from "firebase/auth";
import { useGoogleMaps } from "../../components/GoogleMapsProvider";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

const DriverDashboard = () => {
  const auth = getAuth();
  const driverId = auth.currentUser?.uid;
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [location, setLocation] = useState({ lat: 29.3759, lng: 47.9774 });
  const { isLoaded, loadError } = useGoogleMaps();

  useEffect(() => {
    if (!driverId) return;

    // Fetch orders for current driver
    const fetchOrders = async () => {
      try {
        console.log("📌 جاري جلب الطلبات...");
        const q = query(
          collection(db, "orders"), 
          where("driverId", "==", driverId)
        );
        const querySnapshot = await getDocs(q);
        const data = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setOrders(data);
      } catch (error) {
        console.error("❌ خطأ في جلب الطلبات:", error.message);
      } finally {
        setLoading(false);
      }
    };

    // Update driver's real-time location
    const updateLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.watchPosition(
          async (position) => {
            const { latitude, longitude } = position.coords;
            setLocation({ lat: latitude, lng: longitude });
    
            try {
              await updateDoc(doc(db, "drivers", driverId), {
                location: { lat: latitude, lng: longitude },
              });
            } catch (error) {
              console.error("❌ خطأ في تحديث الموقع:", error.message);
            }
          },
          (error) => console.error("❌ خطأ في جلب الموقع:", error.message),
          { enableHighAccuracy: true }
        );
      }
    };

    fetchOrders();
    updateLocation();
  }, [driverId]);

  // Update order status
  const updateOrderStatus = async (orderId, newStatus) => {
    try {
      await updateDoc(doc(db, "orders", orderId), { status: newStatus });
      setOrders((prev) =>
        prev.map((order) => 
          order.id === orderId ? { ...order, status: newStatus } : order
        )
      );
      alert(`✅ تم تحديث حالة الطلب إلى: ${newStatus}`);
    } catch (error) {
      console.error("❌ خطأ في تحديث حالة الطلب:", error.message);
    }
  };

  // معالجة حالات تحميل الخريطة
  if (loadError) {
    return <div>خطأ في تحميل الخريطة: {loadError.message}</div>;
  }

  if (!isLoaded) {
    return <div>جاري تحميل الخريطة...</div>;
  }

  return (
    <div>
      <Header />
      <div style={{ display: "flex", justifyContent: "space-between", marginTop: "50px" }}>
        
        {/* Driver Map */}
        <div style={{ width: "40%", height: "500px" }}>
          <GoogleMap 
            mapContainerStyle={{ width: "100%", height: "100%" }} 
            center={location} 
            zoom={14}
          >
            <Marker position={location} label="أنا هنا 🚗" />
          </GoogleMap>
        </div>

        {/* Orders Display */}
        <div style={{ width: "55%" }}>
          <h2>طلباتي</h2>
          {loading ? (
            <p>⏳ جاري تحميل البيانات...</p>
          ) : orders.length === 0 ? (
            <p>🔍 لا توجد طلبات حالية.</p>
          ) : (
            <table border="1" width="100%" style={{ marginTop: "20px", textAlign: "center" }}>
              <thead>
                <tr>
                  <th>رقم الطلب</th>
                  <th>اسم العميل</th>
                  <th>الهاتف</th>
                  <th>العنوان</th>
                  <th>قيمة الطلب</th>
                  <th>طريقة الدفع</th>
                  <th>الحالة</th>
                  <th>الإجراءات</th>
                </tr>
              </thead>
              <tbody>
                {orders.map((order, index) => (
                  <tr key={order.id}>
                    <td>{index + 1}</td>
                    <td>{order.customerName}</td>
                    <td>{order.phone}</td>
                    <td>{order.address}</td>
                    <td>{order.amount} دينار</td>
                    <td>{order.paymentMethod}</td>
                    <td>{order.status}</td>
                    <td>
                      {order.status === "قيد التنفيذ" && (
                        <button onClick={() => updateOrderStatus(order.id, "تم الاستلام 🚗")}>
                          🚗 تم الاستلام
                        </button>
                      )}
                      {order.status === "تم الاستلام 🚗" && (
                        <button onClick={() => updateOrderStatus(order.id, "تم التسليم ✅")}>
                          ✅ تم التسليم
                        </button>
                      )}
                      {order.status !== "تم التسليم ✅" && (
                        <button onClick={() => updateOrderStatus(order.id, "ملغي ❌")}>
                          ❌ إلغاء الطلب
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default DriverDashboard;