// src/restaurant/pages/RestaurantLogin.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { auth, db } from '../../firebase';
import './RestaurantLogin.css';

const RestaurantLogin = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    
    if (!email || !password) {
      setError('الرجاء إدخال البريد الإلكتروني وكلمة المرور');
      return;
    }

    setLoading(true);
    setError('');

    try {
      // تسجيل الدخول باستخدام Firebase Authentication
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // التحقق من أن المستخدم هو مطعم من خلال البحث في Firestore
      const restaurantsRef = collection(db, 'restaurants');
      const q = query(restaurantsRef, where('email', '==', email));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        // المستخدم ليس مطعماً
        await auth.signOut(); // تسجيل الخروج
        setError('هذا الحساب غير مسجل كمطعم. الرجاء التواصل مع الإدارة.');
        setLoading(false);
        return;
      }

      // استخراج بيانات المطعم وتخزينها في localStorage
      const restaurantData = querySnapshot.docs[0].data();
      const restaurantId = querySnapshot.docs[0].id;
      
      localStorage.setItem('restaurantId', restaurantId);
      localStorage.setItem('restaurantName', restaurantData.name);
      localStorage.setItem('restaurantEmail', restaurantData.email);
      localStorage.setItem('userRole', 'restaurant');

      // التوجيه إلى صفحة لوحة تحكم المطعم
      navigate('/restaurant/dashboard');
    } catch (error) {
      console.error('خطأ في تسجيل الدخول:', error);
      setError('خطأ في تسجيل الدخول. الرجاء التحقق من البريد الإلكتروني وكلمة المرور.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="restaurant-login-container">
      <div className="restaurant-login-card">
        <div className="restaurant-login-header">
          <h2>تسجيل دخول المطعم</h2>
          <p>أدخل بيانات الدخول الخاصة بالمطعم</p>
        </div>

        {error && <div className="restaurant-login-error">{error}</div>}

        <form onSubmit={handleLogin} className="restaurant-login-form">
          <div className="form-group">
            <label htmlFor="email">البريد الإلكتروني</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="أدخل البريد الإلكتروني"
              disabled={loading}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="password">كلمة المرور</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="أدخل كلمة المرور"
              disabled={loading}
              required
            />
          </div>

          <button type="submit" className="restaurant-login-button" disabled={loading}>
            {loading ? 'جاري تسجيل الدخول...' : 'تسجيل الدخول'}
          </button>
        </form>

        <div className="restaurant-login-footer">
          <p>هل نسيت كلمة المرور؟ <a href="#" onClick={(e) => { e.preventDefault(); alert('الرجاء التواصل مع إدارة النظام لإعادة تعيين كلمة المرور'); }}>اتصل بالإدارة</a></p>
        </div>
      </div>
    </div>
  );
};

export default RestaurantLogin;