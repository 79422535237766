import React, { useState, useEffect } from "react";
import { db } from "../firebase";
import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
} from "firebase/firestore";
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { GoogleMap, Marker } from "@react-google-maps/api";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { useGoogleMaps } from "../components/GoogleMapsProvider";

// تهيئة مفتاح Mapbox
mapboxgl.accessToken = 'pk.eyJ1IjoibXJjY2NyZXciLCJhIjoiY204ZG1kNGx0MmVlZDJqcXU4a25xbGRibCJ9.hoeEc8I4yW_fg0ZBOWD9LA';

const MapboxAddressSearch = ({ onSelect }) => {
  const [query, setQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [loading, setLoading] = useState(false);

  const searchAddresses = async (input) => {
    if (input.length < 3) return;

    setLoading(true);
    try {
      const response = await fetch(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(input)}.json?` +
        `access_token=${mapboxgl.accessToken}&` +
        `country=KW&` + // تخصيص البحث للكويت
        `language=ar`
      );

      const data = await response.json();
      setSuggestions(data.features);
      setLoading(false);
    } catch (error) {
      console.error('خطأ في البحث:', error);
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setQuery(value);
    searchAddresses(value);
  };

  const handleSelectAddress = (address) => {
    setQuery(address.place_name);
    onSelect({
      address: address.place_name,
      location: {
        lat: address.center[1],
        lng: address.center[0]
      }
    });
    setSuggestions([]);
  };

  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <input
        type="text"
        value={query}
        onChange={handleInputChange}
        placeholder="ابحث عن العنوان في الكويت"
        style={{ 
          display: "block", 
          width: "100%", 
          padding: "8px", 
          marginBottom: "10px" 
        }}
      />
      {loading && <div>جاري البحث...</div>}
      {suggestions.length > 0 && (
        <ul style={{
          position: 'absolute',
          top: '100%',
          left: 0,
          width: '100%',
          maxHeight: '200px',
          overflowY: 'auto',
          border: '1px solid #ddd',
          backgroundColor: 'white',
          zIndex: 1000
        }}>
          {suggestions.map((suggestion, index) => (
            <li 
              key={index}
              onClick={() => handleSelectAddress(suggestion)}
              style={{
                padding: '8px',
                cursor: 'pointer',
                ':hover': { backgroundColor: '#f0f0f0' }
              }}
            >
              {suggestion.place_name}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

const DriverManagement = () => {
  const [drivers, setDrivers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editingDriver, setEditingDriver] = useState(null);
  const [updatedData, setUpdatedData] = useState({});
  const [newDriver, setNewDriver] = useState({
    name: "",
    email: "",
    phone: "",
    balance: 0,
    status: "متاح",
    address: "",
    location: { lat: 29.3759, lng: 47.9774 },
  });

  const { isLoaded, loadError } = useGoogleMaps();

  // جلب بيانات السائقين عند تحميل المكون
  useEffect(() => {
    fetchDrivers();
  }, []);

  // استرجاع بيانات السائقين من Firestore
  const fetchDrivers = async () => {
    try {
      setLoading(true);
      console.log("📌 جاري جلب بيانات السائقين...");
      const querySnapshot = await getDocs(collection(db, "drivers"));

      if (querySnapshot.empty) {
        console.warn("⚠️ لا توجد بيانات متاحة في Firestore.");
        setDrivers([]);
      } else {
        const data = querySnapshot.docs.map((doc) => {
          const driver = doc.data();
          return {
            id: doc.id,
            ...driver,
            location: driver.location && driver.location.lat && driver.location.lng
              ? driver.location
              : { lat: 29.3759, lng: 47.9774 },
          };
        });
        console.log("📌 البيانات المسترجعة:", data);
        setDrivers(data);
      }
    } catch (error) {
      console.error("❌ خطأ في جلب بيانات السائقين:", error.message);
    } finally {
      setLoading(false);
    }
  };

  // معالجة اختيار العنوان
  const handleAddressSelect = (selectedAddress) => {
    setNewDriver({
      ...newDriver,
      address: selectedAddress.address,
      location: {
        lat: selectedAddress.location.lat,
        lng: selectedAddress.location.lng
      }
    });
  };

  // إضافة سائق جديد
  const addDriver = async () => {
    if (!newDriver.name || !newDriver.email || !newDriver.phone || !newDriver.address) {
      alert("❌ يجب ملء جميع الحقول!");
      return;
    }

    try {
      setLoading(true);
      const docRef = await addDoc(collection(db, "drivers"), newDriver);
      setDrivers([...drivers, { id: docRef.id, ...newDriver }]);
      alert("✅ تم إضافة السائق بنجاح!");
      setNewDriver({
        name: "",
        email: "",
        phone: "",
        address: "",
        balance: 0,
        status: "متاح",
        location: { lat: 29.3759, lng: 47.9774 },
      });
    } catch (error) {
      console.error("❌ خطأ في إضافة السائق:", error.message);
    } finally {
      setLoading(false);
    }
  };

  // تعديل بيانات السائق
  const startEditing = (driver) => {
    setEditingDriver(driver.id);
    setUpdatedData({
      name: driver.name,
      phone: driver.phone,
      balance: driver.balance,
      address: driver.address,
      status: driver.status,
    });
  };

  const saveChanges = async () => {
    try {
      setLoading(true);
      await updateDoc(doc(db, "drivers", editingDriver), updatedData);
      setDrivers((prev) =>
        prev.map((d) => (d.id === editingDriver ? { ...d, ...updatedData } : d))
      );
      setEditingDriver(null);
      alert("✅ تم تعديل السائق بنجاح!");
    } catch (error) {
      console.error("❌ خطأ في تعديل السائق:", error.message);
    } finally {
      setLoading(false);
    }
  };

  // تحديث حالة السائق (متاح/غير متاح)
  const toggleDriverStatus = async (driver) => {
    try {
      setLoading(true);
      const newStatus = driver.status === "متاح" ? "غير متاح" : "متاح";
      await updateDoc(doc(db, "drivers", driver.id), { status: newStatus });
      setDrivers((prev) =>
        prev.map((d) => (d.id === driver.id ? { ...d, status: newStatus } : d))
      );
      alert(`✅ تم ${newStatus === "متاح" ? "تفعيل" : "إيقاف"} السائق بنجاح!`);
    } catch (error) {
      console.error("❌ خطأ في تحديث حالة السائق:", error.message);
    } finally {
      setLoading(false);
    }
  };

  // حذف سائق
  const deleteDriver = async (driverId) => {
    if (window.confirm("هل أنت متأكد أنك تريد حذف هذا السائق؟")) {
      try {
        setLoading(true);
        await deleteDoc(doc(db, "drivers", driverId));
        setDrivers(drivers.filter((d) => d.id !== driverId));
        alert("✅ تم حذف السائق بنجاح!");
      } catch (error) {
        console.error("❌ خطأ في حذف السائق:", error.message);
      } finally {
        setLoading(false);
      }
    }
  };

  // مكون عرض حالة التحميل
  const LoadingSpinner = () => (
    <div style={{
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "rgba(255, 255, 255, 0.7)",
      zIndex: 1000
    }}>
      <div style={{
        width: "50px",
        height: "50px",
        border: "5px solid #f3f3f3",
        borderTop: "5px solid #3498db",
        borderRadius: "50%",
        animation: "spin 1s linear infinite"
      }} />
      <style jsx>{`
        @keyframes spin {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
        }
      `}</style>
    </div>
  );

  // معالجة أخطاء تحميل الخريطة
  if (loadError) {
    return (
      <div>
        <Header />
        <div style={{ textAlign: 'center', marginTop: '50px' }}>
          <h2>خطأ في تحميل الخريطة</h2>
          <p>{loadError.message}</p>
        </div>
        <Footer />
      </div>
    );
  }

  return (
    <div>
      <Header />
      {loading && <LoadingSpinner />}
      <div style={{ display: "flex", justifyContent: "space-between", marginTop: "50px" }}>
        {/* خريطة السائقين */}
        <div style={{ width: "40%", height: "500px" }}>
          {isLoaded ? (
            <GoogleMap 
              mapContainerStyle={{ width: "100%", height: "100%" }} 
              center={{ lat: 29.3759, lng: 47.9774 }} 
              zoom={12}
            >
              {drivers.map((driver) =>
                driver.location && driver.location.lat && driver.location.lng ? (
                  <Marker 
                    key={driver.id} 
                    position={driver.location} 
                    label={driver.name}
                    icon={{
                      url: driver.status === "متاح" ? "/green-car.png" : "/red-car.png",
                    }} 
                  />
                ) : null
              )}
            </GoogleMap>
          ) : (
            <div style={{ 
              width: "100%", 
              height: "100%", 
              display: "flex", 
              justifyContent: "center", 
              alignItems: "center",
              backgroundColor: "#f8f9fa",
              borderRadius: "5px" 
            }}>
              <p>جاري تحميل الخريطة...</p>
            </div>
          )}
        </div>

        {/* إدارة السائقين */}
        <div style={{ width: "55%" }}>
          <h2>إدارة السائقين</h2>
          <div style={{ border: "1px solid gray", padding: "15px", background: "#f9f9f9", borderRadius: "5px" }}>
            <h3>إضافة سائق جديد</h3>
            <input 
              type="text" 
              placeholder="اسم السائق" 
              value={newDriver.name} 
              onChange={(e) => setNewDriver({ ...newDriver, name: e.target.value })}
              style={{ display: "block", width: "100%", padding: "8px", marginBottom: "10px" }}
            />
            <input 
              type="email" 
              placeholder="الإيميل" 
              value={newDriver.email} 
              onChange={(e) => setNewDriver({ ...newDriver, email: e.target.value })}
              style={{ display: "block", width: "100%", padding: "8px", marginBottom: "10px" }}
            />
            <input 
              type="text" 
              placeholder="رقم الهاتف" 
              value={newDriver.phone} 
              onChange={(e) => setNewDriver({ ...newDriver, phone: e.target.value })}
              style={{ display: "block", width: "100%", padding: "8px", marginBottom: "10px" }}
            />
            
            <MapboxAddressSearch onSelect={handleAddressSelect} />
            
            <button 
              onClick={addDriver} 
              disabled={loading}
              style={{
                backgroundColor: "#28a745",
                color: "white",
                border: "none",
                borderRadius: "4px",
                padding: "8px 15px",
                cursor: loading ? "not-allowed" : "pointer"
              }}
            >
              إضافة السائق
            </button>
          </div>

          {/* عرض قائمة السائقين */}
          <table border="1" width="100%" style={{ marginTop: "20px", textAlign: "center" }}>
            <thead>
              <tr>
                <th>الاسم</th>
                <th>الحالة</th>
                <th>الهاتف</th>
                <th>الإجراءات</th>
              </tr>
            </thead>
            <tbody>
              {drivers.length === 0 && !loading ? (
                <tr>
                  <td colSpan="4">لا يوجد سائقين متاحين حالياً</td>
                </tr>
              ) : (
                drivers.map((driver) => (
                  <tr key={driver.id}>
                    <td>{driver.name}</td>
                    <td>{driver.status}</td>
                    <td>{driver.phone}</td>
                    <td>
                      <button 
                        onClick={() => deleteDriver(driver.id)} 
                        disabled={loading}
                        style={{
                          backgroundColor: "#dc3545",
                          color: "white",
                          border: "none",
                          borderRadius: "4px",
                          padding: "5px 10px",
                          margin: "2px",
                          cursor: loading ? "not-allowed" : "pointer"
                        }}
                      >
                        🗑️ حذف
                      </button>
                      <button 
                        onClick={() => toggleDriverStatus(driver)} 
                        disabled={loading}
                        style={{
                          backgroundColor: driver.status === "متاح" ? "#dc3545" : "#28a745",
                          color: "white",
                          border: "none",
                          borderRadius: "4px",
                          padding: "5px 10px",
                          margin: "2px",
                          cursor: loading ? "not-allowed" : "pointer"
                        }}
                      >
                        {driver.status === "متاح" ? "🔄 إيقاف" : "🔄 تفعيل"}
                      </button>
                      <button 
                        onClick={() => startEditing(driver)} 
                        disabled={loading}
                        style={{
                          backgroundColor: "#17a2b8",
                          color: "white",
                          border: "none",
                          borderRadius: "4px",
                          padding: "5px 10px",
                          margin: "2px",
                          cursor: loading ? "not-allowed" : "pointer"
                        }}
                      >
                        ✏️ تعديل
                      </button>
                      {editingDriver === driver.id && (
                        <button 
                          onClick={saveChanges} 
                          disabled={loading}
                          style={{
                            backgroundColor: "#ffc107",
                            color: "black",
                            border: "none",
                            borderRadius: "4px",
                            padding: "5px 10px",
                            margin: "2px",
                            cursor: loading ? "not-allowed" : "pointer"
                          }}
                        >
                          💾 حفظ التغييرات
                        </button>
                      )}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default DriverManagement;