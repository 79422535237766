// src/restaurant/components/RestaurantSidebar.js
import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { auth } from '../firebase';
import './RestaurantSidebar.css';

const RestaurantSidebar = () => {
  const navigate = useNavigate();
  const restaurantName = localStorage.getItem('restaurantName') || 'المطعم';

  const handleLogout = async () => {
    try {
      await signOut(auth);
      localStorage.removeItem('restaurantId');
      localStorage.removeItem('restaurantName');
      localStorage.removeItem('restaurantEmail');
      localStorage.removeItem('userRole');
      navigate('/restaurant/login');
    } catch (error) {
      console.error('خطأ في تسجيل الخروج:', error);
    }
  };

  return (
    <div className="restaurant-sidebar">
      <div className="restaurant-sidebar-header">
        <h2>AT Delivery</h2>
        <p>{restaurantName}</p>
      </div>

      <div className="restaurant-sidebar-menu">
        <NavLink to="/restaurant/dashboard" className={({ isActive }) => isActive ? 'menu-item active' : 'menu-item'}>
          <span className="menu-icon">🏠</span>
          <span className="menu-text">الرئيسية</span>
        </NavLink>

        <NavLink to="/restaurant/orders/new" className={({ isActive }) => isActive ? 'menu-item active' : 'menu-item'}>
          <span className="menu-icon">➕</span>
          <span className="menu-text">طلب جديد</span>
        </NavLink>

        <NavLink to="/restaurant/orders" className={({ isActive }) => isActive ? 'menu-item active' : 'menu-item'}>
          <span className="menu-icon">📦</span>
          <span className="menu-text">إدارة الطلبات</span>
        </NavLink>

        <NavLink to="/restaurant/reports" className={({ isActive }) => isActive ? 'menu-item active' : 'menu-item'}>
          <span className="menu-icon">📊</span>
          <span className="menu-text">التقارير</span>
        </NavLink>

        <NavLink to="/restaurant/wallet" className={({ isActive }) => isActive ? 'menu-item active' : 'menu-item'}>
          <span className="menu-icon">💰</span>
          <span className="menu-text">المحفظة</span>
        </NavLink>

        <NavLink to="/restaurant/settings" className={({ isActive }) => isActive ? 'menu-item active' : 'menu-item'}>
          <span className="menu-icon">⚙️</span>
          <span className="menu-text">الإعدادات</span>
        </NavLink>
      </div>

      <div className="restaurant-sidebar-footer">
        <button className="logout-button" onClick={handleLogout}>
          <span className="logout-icon">🚪</span>
          <span className="logout-text">تسجيل الخروج</span>
        </button>
      </div>
    </div>
  );
};

export default RestaurantSidebar;