// src/components/ProtectedRoute.js
import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { auth } from '../firebase';
import { onAuthStateChanged } from 'firebase/auth';

const ProtectedRoute = ({ children, role }) => {
  const [loading, setLoading] = useState(true);
  const [authenticated, setAuthenticated] = useState(false);
  const [hasRole, setHasRole] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setLoading(true);
      
      if (user) {
        setAuthenticated(true);
        
        // التحقق من الدور من localStorage
        const userRole = localStorage.getItem('userRole');
        if (userRole === role) {
          setHasRole(true);
        } else {
          setHasRole(false);
        }
      } else {
        setAuthenticated(false);
        setHasRole(false);
      }
      
      setLoading(false);
    });
    
    return () => unsubscribe();
  }, [role]);

  if (loading) {
    return (
      <div className="loading-spinner-container">
        <div className="loading-spinner"></div>
        <p>جاري التحميل...</p>
      </div>
    );
  }

  if (!authenticated) {
    // توجيه إلى صفحة تسجيل الدخول المناسبة
    if (role === 'restaurant') {
      return <Navigate to="/restaurant/login" />;
    }
    return <Navigate to="/login" />;
  }

  if (!hasRole) {
    // المستخدم مصادق عليه ولكن ليس لديه الدور المطلوب
    return <Navigate to="/unauthorized" />;
  }

  return children;
};

export default ProtectedRoute;