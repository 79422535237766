import React from 'react';
import { useNavigate } from 'react-router-dom';


const HomePage = () => {
  const navigate = useNavigate();

  return (
    <div>
      

      <div style={{ 
        textAlign: 'center', 
        marginTop: '50px', 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center' 
      }}>
        <h1 style={{ fontSize: '2rem', marginBottom: '30px' }}>
          شركة اي تي ديليفري لتوصيل الطلبات الاستهلاكية
        </h1>

        {/* حاوية الأزرار */}
        <div style={{ 
          display: 'flex', 
          justifyContent: 'center', 
          gap: '20px' 
        }}>
          {/* زر تسجيل الدخول */}
          <button 
            onClick={() => navigate('/login')}
            style={{
              backgroundColor: '#007bff',
              color: 'white',
              padding: '12px 25px',
              border: 'none',
              borderRadius: '8px',
              fontSize: '16px',
              cursor: 'pointer',
              transition: 'background-color 0.3s ease'
            }}
            onMouseOver={(e) => e.target.style.backgroundColor = '#0056b3'}
            onMouseOut={(e) => e.target.style.backgroundColor = '#007bff'}
          >
            تسجيل الدخول
          </button>

          {/* زر إنشاء حساب جديد */}
          <button 
            onClick={() => navigate('/signup')}
            style={{
              backgroundColor: '#28a745',
              color: 'white',
              padding: '12px 25px',
              border: 'none',
              borderRadius: '8px',
              fontSize: '16px',
              cursor: 'pointer',
              transition: 'background-color 0.3s ease'
            }}
            onMouseOver={(e) => e.target.style.backgroundColor = '#218838'}
            onMouseOut={(e) => e.target.style.backgroundColor = '#28a745'}
          >
            إنشاء حساب جديد
          </button>
        </div>
      </div>

      
    </div>
  );
};

export default HomePage;