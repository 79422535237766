import React, { useState, useEffect } from "react";
import { db } from "../../firebase";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

const DriverProfile = () => {
  const auth = getAuth();
  const driverId = auth.currentUser?.uid;
  const [driver, setDriver] = useState(null);
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState("متاح");

  useEffect(() => {
    if (!driverId) return;

    const fetchDriver = async () => {
      try {
        const driverRef = doc(db, "drivers", driverId);
        const driverSnap = await getDoc(driverRef);

        if (driverSnap.exists()) {
          setDriver(driverSnap.data());
          setStatus(driverSnap.data().status || "متاح");
        } else {
          console.warn("🚫 لا يوجد بيانات لهذا السائق.");
        }
      } catch (error) {
        console.error("❌ خطأ في جلب بيانات السائق:", error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchDriver();
  }, [driverId]);

  // ✅ تحديث حالة السائق
  const toggleStatus = async () => {
    try {
      const newStatus = status === "متاح" ? "غير متاح" : "متاح";
      await updateDoc(doc(db, "drivers", driverId), { status: newStatus });
      setStatus(newStatus);
      alert(`✅ تم تحديث الحالة إلى: ${newStatus}`);
    } catch (error) {
      console.error("❌ خطأ في تحديث الحالة:", error.message);
    }
  };

  return (
    <div>
      <Header />
      <div style={{ textAlign: "center", marginTop: "50px" }}>
        <h2>ملف السائق</h2>
        {loading ? (
          <p>⏳ جاري تحميل البيانات...</p>
        ) : driver ? (
          <div style={{ border: "1px solid gray", padding: "15px", width: "50%", margin: "auto", background: "#f9f9f9", borderRadius: "5px" }}>
            <p><strong>اسم السائق:</strong> {driver.name}</p>
            <p><strong>رقم الهاتف:</strong> {driver.phone}</p>
            <p><strong>العنوان:</strong> {driver.address}</p>
            <p><strong>الحالة الحالية:</strong> {status}</p>
            <button onClick={toggleStatus}>{status === "متاح" ? "🔴 غير متاح" : "🟢 متاح"}</button>
          </div>
        ) : (
          <p>🚫 لا توجد بيانات متاحة.</p>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default DriverProfile;
