// src/restaurant/pages/RestaurantOrders.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, query, where, getDocs, orderBy } from 'firebase/firestore';
import { db, auth } from '../../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import RestaurantSidebar from '../../components/RestaurantSidebar';
import RestaurantHeader from '../../components/RestaurantHeader';
import './RestaurantOrders.css';

const RestaurantOrders = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState('all'); // 'all', 'active', 'completed', 'cancelled'
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (!user) {
        navigate('/restaurant/login');
        return;
      }

      const restaurantId = localStorage.getItem('restaurantId');
      if (!restaurantId) {
        navigate('/restaurant/login');
        return;
      }

      await fetchOrders(restaurantId);
    });

    return () => unsubscribe();
  }, [navigate]);

  const fetchOrders = async (restaurantId) => {
    try {
      setLoading(true);
      
      const ordersRef = collection(db, 'orders');
      const q = query(
        ordersRef,
        where('restaurantId', '==', restaurantId),
        orderBy('createdAt', 'desc')
      );
      
      const querySnapshot = await getDocs(q);
      const ordersData = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        formattedDate: doc.data().createdAt ? formatTimestamp(doc.data().createdAt) : 'غير متاح'
      }));
      
      setOrders(ordersData);
    } catch (error) {
      console.error('خطأ في جلب الطلبات:', error);
    } finally {
      setLoading(false);
    }
  };

  const formatTimestamp = (timestamp) => {
    if (!timestamp || !timestamp.toDate) return 'غير متاح';
    
    const date = timestamp.toDate();
    return date.toLocaleDateString('ar-EG', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  const handleViewOrder = (orderId) => {
    navigate(`/restaurant/orders/${orderId}`);
  };

  const handleCreateOrder = () => {
    navigate('/restaurant/orders/new');
  };

  const filteredOrders = orders.filter(order => {
    // تصفية حسب الحالة
    if (filter === 'active' && (order.status === 'ملغي ❌' || order.status === 'تم التسليم ✅')) {
      return false;
    }
    if (filter === 'completed' && order.status !== 'تم التسليم ✅') {
      return false;
    }
    if (filter === 'cancelled' && order.status !== 'ملغي ❌') {
      return false;
    }

    // تصفية حسب البحث
    if (searchTerm) {
      const searchLower = searchTerm.toLowerCase();
      return (
        order.customerName?.toLowerCase().includes(searchLower) ||
        order.phone?.toLowerCase().includes(searchLower) ||
        order.address?.toLowerCase().includes(searchLower) ||
        order.id.toLowerCase().includes(searchLower)
      );
    }

    return true;
  });

  const getStatusColorClass = (status) => {
    if (status === 'تم التسليم ✅') return 'status-completed';
    if (status === 'ملغي ❌') return 'status-cancelled';
    if (status === 'تم الاستلام 🚗') return 'status-picked';
    return 'status-pending';
  };

  if (loading) {
    return (
      <div className="restaurant-loading">
        <div className="restaurant-spinner"></div>
        <p>جاري تحميل البيانات...</p>
      </div>
    );
  }

  return (
    <div className="restaurant-orders-page">
      <RestaurantSidebar />
      <div className="restaurant-orders-content">
        <RestaurantHeader restaurantName={localStorage.getItem('restaurantName') || 'المطعم'} />
        
        <div className="orders-container">
          <div className="orders-header">
            <h1>إدارة الطلبات</h1>
            <button 
              className="create-order-btn" 
              onClick={handleCreateOrder}
            >
              + طلب جديد
            </button>
          </div>
          
          <div className="orders-filters">
            <div className="filter-tabs">
              <button 
                className={`filter-tab ${filter === 'all' ? 'active' : ''}`} 
                onClick={() => setFilter('all')}
              >
                جميع الطلبات
              </button>
              <button 
                className={`filter-tab ${filter === 'active' ? 'active' : ''}`} 
                onClick={() => setFilter('active')}
              >
                النشطة
              </button>
              <button 
                className={`filter-tab ${filter === 'completed' ? 'active' : ''}`} 
                onClick={() => setFilter('completed')}
              >
                المكتملة
              </button>
              <button 
                className={`filter-tab ${filter === 'cancelled' ? 'active' : ''}`} 
                onClick={() => setFilter('cancelled')}
              >
                الملغاة
              </button>
            </div>
            
            <div className="search-box">
              <input 
                type="text" 
                placeholder="البحث عن طلب..." 
                value={searchTerm} 
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <span className="search-icon">🔍</span>
            </div>
          </div>
          
          {filteredOrders.length === 0 ? (
            <div className="no-orders">
              <p>لا توجد طلبات متاحة{filter !== 'all' ? ' في هذه الفئة' : ''}</p>
              {filter !== 'all' && (
                <button 
                  className="view-all-btn" 
                  onClick={() => setFilter('all')}
                >
                  عرض جميع الطلبات
                </button>
              )}
            </div>
          ) : (
            <div className="orders-table-container">
              <table className="orders-table">
                <thead>
                  <tr>
                    <th>رقم الطلب</th>
                    <th>العميل</th>
                    <th>العنوان</th>
                    <th>السائق</th>
                    <th>المبلغ</th>
                    <th>التاريخ</th>
                    <th>الحالة</th>
                    <th>الإجراءات</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredOrders.map((order) => (
                    <tr key={order.id}>
                      <td className="order-id">{order.id.slice(0, 8)}...</td>
                      <td>
                        <div className="customer-info">
                          <span className="customer-name">{order.customerName}</span>
                          <span className="customer-phone">{order.phone}</span>
                        </div>
                      </td>
                      <td className="order-address" title={order.address}>
                        {order.address.length > 25 ? `${order.address.slice(0, 25)}...` : order.address}
                      </td>
                      <td>{order.driverName}</td>
                      <td className="order-amount">{order.amount?.toFixed(3)} د.ك</td>
                      <td>{order.formattedDate}</td>
                      <td>
                        <span className={`status-badge ${getStatusColorClass(order.status)}`}>
                          {order.status}
                        </span>
                      </td>
                      <td>
                        <button 
                          className="view-order-btn" 
                          onClick={() => handleViewOrder(order.id)}
                        >
                          عرض
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RestaurantOrders;