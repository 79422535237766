// src/firebase.js
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getMessaging } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyAfpEXpKaRnVTfOdRpZaHBhoGCGlsHbVE8",
  authDomain: "atdelivery-9d139.firebaseapp.com",
  projectId: "atdelivery-9d139",
  databaseURL: "https://atdelivery-9d139.firebaseio.com",
  storageBucket: "atdelivery-9d139.appspot.com",
  messagingSenderId: "76043371440",
  appId: "1:76043371440:web:d2a57531269b997892cc75",
  vapidKey: "SptifuBGYaJ8qu7vFlT4xFReDh1LLB_j3DG4mkYgu70"
};

// تهيئة Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);

// تهيئة Messaging (فقط إذا كان المتصفح يدعم ذلك وكان HTTPS أو localhost)
export let messaging = null;
try {
  if (
    typeof window !== 'undefined' && 
    typeof navigator !== 'undefined' &&
    (window.location.protocol === 'https:' || window.location.hostname === 'localhost') &&
    'serviceWorker' in navigator
  ) {
    messaging = getMessaging(app);
    console.log('✅ تم تهيئة Firebase Messaging بنجاح');
  }
} catch (error) {
  console.error('❌ خطأ في تهيئة Firebase Messaging:', error);
}

export default app;