// src/Router.js
import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { sendNotification } from "./utils/notifications";

// صفحات الواجهة الرئيسية
import HomePage from './pages/HomePage';
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import Dashboard from "./pages/Dashboard";
import Orders from "./pages/Orders";
import NotificationsPage from "./pages/NotificationsPage";
import NotFound from "./pages/NotFound";

// صفحات الإدارة
import AdminOrders from "./admin/AdminOrders";
import DriverManagement from "./admin/DriverManagement";
import RestaurantManagement from "./admin/RestaurantManagement";

// صفحات العميل
import CustomerOrders from "./customer/pages/CustomerOrders";

// صفحات المطعم
import RestaurantLogin from "./restaurant/pages/RestaurantLogin";
import RestaurantDashboard from "./restaurant/pages/RestaurantDashboard";
import NewOrder from "./restaurant/pages/NewOrder";
import RestaurantOrders from "./restaurant/pages/RestaurantOrders";
import OrderDetails from "./restaurant/pages/OrderDetails";
import Wallet from "./restaurant/pages/Wallet";
import Reports from "./restaurant/pages/Reports";
import Settings from "./restaurant/pages/Settings";
import RestaurantNotifications from "./restaurant/pages/RestaurantNotifications";

// صفحات السائق
import DriverDashboard from "./driver/pages/DriverDashboard";
import DriverOrders from "./driver/pages/DriverOrders";
import DriverProfile from "./driver/pages/DriverProfile";

// مكونات مشتركة
import Header from "./components/Header";
import Footer from "./components/Footer";
import ProtectedRoute from "./components/ProtectedRoute";

const AppRouter = () => {
  useEffect(() => {
    // إرسال إشعار عند بدء التطبيق (اختياري)
    const userRole = localStorage.getItem("userRole");
    if (userRole === "admin") {
      sendNotification("admin", "system", "🚀 التطبيق يعمل بنجاح!");
    }
  }, []);

  return (
    <Router>
      <Routes>
        {/* الصفحات العامة - تظهر مع Header & Footer */}
        <Route path="/" element={<PublicLayout><HomePage /></PublicLayout>} />
        <Route path="/login" element={<PublicLayout><Login /></PublicLayout>} />
        <Route path="/signup" element={<PublicLayout><Signup /></PublicLayout>} />
        <Route path="/dashboard" element={<PublicLayout><Dashboard /></PublicLayout>} />
        <Route path="/orders" element={<PublicLayout><Orders /></PublicLayout>} />
        <Route path="/notifications" element={<PublicLayout><NotificationsPage /></PublicLayout>} />

        {/* صفحات المطعم - لها تخطيط خاص بها */}
        <Route path="/restaurant/login" element={<RestaurantLogin />} />
        <Route path="/restaurant/dashboard" element={
          <ProtectedRoute role="restaurant">
            <RestaurantDashboard />
          </ProtectedRoute>
        } />
        <Route path="/restaurant/orders/new" element={
          <ProtectedRoute role="restaurant">
            <NewOrder />
          </ProtectedRoute>
        } />
        <Route path="/restaurant/orders" element={
          <ProtectedRoute role="restaurant">
            <RestaurantOrders />
          </ProtectedRoute>
        } />
        <Route path="/restaurant/orders/:id" element={
          <ProtectedRoute role="restaurant">
            <OrderDetails />
          </ProtectedRoute>
        } />
        <Route path="/restaurant/wallet" element={
          <ProtectedRoute role="restaurant">
            <Wallet />
          </ProtectedRoute>
        } />
        <Route path="/restaurant/reports" element={
          <ProtectedRoute role="restaurant">
            <Reports />
          </ProtectedRoute>
        } />
        <Route path="/restaurant/settings" element={
          <ProtectedRoute role="restaurant">
            <Settings />
          </ProtectedRoute>
        } />
        <Route path="/restaurant/notifications" element={
          <ProtectedRoute role="restaurant">
            <RestaurantNotifications />
          </ProtectedRoute>
        } />

        {/* صفحات الإدارة */}
        <Route path="/admin/orders" element={
          <ProtectedRoute role="admin">
            <AdminOrders />
          </ProtectedRoute>
        } />
        <Route path="/admin/driver-management" element={
          <ProtectedRoute role="admin">
            <DriverManagement />
          </ProtectedRoute>
        } />
        <Route path="/admin/restaurant-management" element={
          <ProtectedRoute role="admin">
            <RestaurantManagement />
          </ProtectedRoute>
        } />

        {/* صفحات العميل */}
        <Route path="/customer/orders" element={
          <ProtectedRoute role="customer">
            <CustomerOrders />
          </ProtectedRoute>
        } />

        {/* صفحات السائق */}
        <Route path="/driver/dashboard" element={
          <ProtectedRoute role="driver">
            <DriverDashboard />
          </ProtectedRoute>
        } />
        <Route path="/driver/orders" element={
          <ProtectedRoute role="driver">
            <DriverOrders />
          </ProtectedRoute>
        } />
        <Route path="/driver/profile" element={
          <ProtectedRoute role="driver">
            <DriverProfile />
          </ProtectedRoute>
        } />

        {/* صفحة 404 */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};

// مكون لتخطيط الصفحات العامة
const PublicLayout = ({ children }) => (
  <>
    <Header />
    {children}
    <Footer />
  </>
);

export default AppRouter;