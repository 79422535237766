import React, { useState, useEffect } from "react";
import { db } from "../../firebase";
import { collection, getDocs, updateDoc, doc } from "firebase/firestore";
import { GoogleMap, Marker, LoadScript } from "@react-google-maps/api";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

const GOOGLE_MAPS_API_KEY = "AIzaSyAfpEXpKaRnVTfOdRpZaHBhoGCGlsHbVE8"; // 🔹 استبدل بمفتاحك الفعلي

const DriverOrders = ({ driverEmail }) => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // ✅ جلب الطلبات للسائق من قاعدة البيانات
    const fetchOrders = async () => {
      try {
        console.log("📌 جاري جلب الطلبات...");
        const querySnapshot = await getDocs(collection(db, "orders"));
        const driverOrders = querySnapshot.docs
          .map((doc) => ({ id: doc.id, ...doc.data() }))
          .filter((order) => order.driverEmail === driverEmail);
        setOrders(driverOrders);
      } catch (error) {
        console.error("❌ خطأ في جلب الطلبات:", error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, [driverEmail]);

  // ✅ تحديث حالة الطلب عند استلامه
  const acceptOrder = async (orderId) => {
    try {
      await updateDoc(doc(db, "orders", orderId), { status: "تم الاستلام 🚗" });
      setOrders((prev) =>
        prev.map((order) =>
          order.id === orderId ? { ...order, status: "تم الاستلام 🚗" } : order
        )
      );
      alert("✅ تم استلام الطلب!");
    } catch (error) {
      console.error("❌ خطأ في استلام الطلب:", error.message);
    }
  };

  // ✅ تحديث حالة الطلب عند التسليم
  const completeOrder = async (orderId) => {
    try {
      await updateDoc(doc(db, "orders", orderId), { status: "تم التسليم ✅" });
      setOrders((prev) =>
        prev.map((order) =>
          order.id === orderId ? { ...order, status: "تم التسليم ✅" } : order
        )
      );
      alert("✅ تم تسليم الطلب!");
    } catch (error) {
      console.error("❌ خطأ في تسليم الطلب:", error.message);
    }
  };

  return (
    <div>
      <Header />
      <div style={{ display: "flex", justifyContent: "space-between", marginTop: "50px" }}>

        {/* ✅ خريطة الطلبات */}
        <div style={{ width: "40%", height: "500px" }}>
          <LoadScript googleMapsApiKey={GOOGLE_MAPS_API_KEY}>
            <GoogleMap mapContainerStyle={{ width: "100%", height: "100%" }} center={{ lat: 29.3759, lng: 47.9774 }} zoom={12}>
              {orders.map((order) => (
                <Marker
                  key={order.id}
                  position={order.customerLocation || { lat: 29.3759, lng: 47.9774 }}
                  label={order.status === "تم التسليم ✅" ? "✅" : order.status === "تم الاستلام 🚗" ? "🚗" : "📦"}
                />
              ))}
            </GoogleMap>
          </LoadScript>
        </div>

        {/* ✅ عرض الطلبات للسائق */}
        <div style={{ width: "55%" }}>
          <h2>طلباتي</h2>
          {loading ? (
            <p>⏳ جاري تحميل البيانات...</p>
          ) : orders.length === 0 ? (
            <p>⚠️ لا توجد طلبات متاحة.</p>
          ) : (
            <table border="1" width="100%" style={{ marginTop: "20px", textAlign: "center" }}>
              <thead>
                <tr>
                  <th>رقم الطلب</th>
                  <th>اسم العميل</th>
                  <th>الهاتف</th>
                  <th>العنوان</th>
                  <th>حالة الطلب</th>
                  <th>الإجراءات</th>
                </tr>
              </thead>
              <tbody>
                {orders.map((order, index) => (
                  <tr key={order.id}>
                    <td>{index + 1}</td>
                    <td>{order.customerName}</td>
                    <td>{order.phone}</td>
                    <td>{order.address}</td>
                    <td>{order.status}</td>
                    <td>
                      {order.status === "قيد التنفيذ 🚀" && (
                        <button onClick={() => acceptOrder(order.id)}>📦 استلام الطلب</button>
                      )}
                      {order.status === "تم الاستلام 🚗" && (
                        <button onClick={() => completeOrder(order.id)}>✅ تسليم الطلب</button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default DriverOrders;
