// src/restaurant/pages/NewOrder.js
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, query, where, getDocs, addDoc, updateDoc, doc, serverTimestamp, getDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { GoogleMap, Marker, DirectionsRenderer } from '@react-google-maps/api';
import { useGoogleMaps } from '../../components/GoogleMapsProvider';
import RestaurantSidebar from '../../components/RestaurantSidebar';
import RestaurantHeader from '../../components/RestaurantHeader';
import { sendNotification } from '../../utils/notifications';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import './NewOrder.css';

// تهيئة مفتاح Mapbox
mapboxgl.accessToken = 'pk.eyJ1IjoibXJjY2NyZXciLCJhIjoiY204ZG1kNGx0MmVlZDJqcXU4a25xbGRibCJ9.hoeEc8I4yW_fg0ZBOWD9LA';

const MapboxAddressSearch = ({ onSelect }) => {
  const [query, setQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [loading, setLoading] = useState(false);

  const searchAddresses = async (input) => {
    if (input.length < 3) return;

    setLoading(true);
    try {
      const response = await fetch(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(input)}.json?` +
        `access_token=${mapboxgl.accessToken}&` +
        `country=KW&` + // تخصيص البحث للكويت
        `language=ar`
      );

      const data = await response.json();
      setSuggestions(data.features);
      setLoading(false);
    } catch (error) {
      console.error('خطأ في البحث:', error);
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setQuery(value);
    searchAddresses(value);
  };

  const handleSelectAddress = (address) => {
    setQuery(address.place_name);
    onSelect({
      address: address.place_name,
      location: {
        lat: address.center[1],
        lng: address.center[0]
      }
    });
    setSuggestions([]);
  };

  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <input
        type="text"
        value={query}
        onChange={handleInputChange}
        placeholder="ابحث عن العنوان في الكويت"
        style={{ 
          display: "block", 
          width: "100%", 
          padding: "8px", 
          marginBottom: "10px" 
        }}
      />
      {loading && <div>جاري البحث...</div>}
      {suggestions.length > 0 && (
        <ul style={{
          position: 'absolute',
          top: '100%',
          left: 0,
          width: '100%',
          maxHeight: '200px',
          overflowY: 'auto',
          border: '1px solid #ddd',
          backgroundColor: 'white',
          zIndex: 1000
        }}>
          {suggestions.map((suggestion, index) => (
            <li 
              key={index}
              onClick={() => handleSelectAddress(suggestion)}
              style={{
                padding: '8px',
                cursor: 'pointer',
                ':hover': { backgroundColor: '#f0f0f0' }
              }}
            >
              {suggestion.place_name}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

const NewOrder = () => {
  const { isLoaded } = useGoogleMaps();
  const [drivers, setDrivers] = useState([]);
  const [selectedDriver, setSelectedDriver] = useState(null);
  const [deliveryInfo, setDeliveryInfo] = useState({
    customerName: '',
    phone: '',
    address: '',
    notes: '',
    amount: '',
    paymentMethod: 'نقدي'
  });
  const [restaurantData, setRestaurantData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [directions, setDirections] = useState(null);
  const [distance, setDistance] = useState(0);
  const [deliveryFee, setDeliveryFee] = useState(0);
  const navigate = useNavigate();
  const restaurantId = localStorage.getItem('restaurantId');

  useEffect(() => {
    if (!restaurantId) {
      navigate('/restaurant/login');
      return;
    }

    const fetchData = async () => {
      try {
        // جلب بيانات المطعم
        const restaurantDoc = await getDoc(doc(db, 'restaurants', restaurantId));
        if (!restaurantDoc.exists()) {
          navigate('/restaurant/login');
          return;
        }
        setRestaurantData({ id: restaurantDoc.id, ...restaurantDoc.data() });

        // جلب السائقين المتاحين
        const driversRef = collection(db, 'drivers');
        const q = query(driversRef, where('status', '==', 'متاح'));
        const querySnapshot = await getDocs(q);
        const availableDrivers = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setDrivers(availableDrivers);
      } catch (error) {
        console.error('خطأ في جلب البيانات:', error);
        setError('حدث خطأ في جلب البيانات. يرجى المحاولة مرة أخرى.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [navigate, restaurantId]);

  const handleAddressSelect = (selectedAddress) => {
    setDeliveryInfo({
      ...deliveryInfo,
      address: selectedAddress.address
    });

    // حساب المسافة والرسوم بين المطعم والعميل
    if (restaurantData.location) {
      const directionsService = new window.google.maps.DirectionsService();
      const origin = new window.google.maps.LatLng(
        restaurantData.location.lat,
        restaurantData.location.lng
      );
      const destination = new window.google.maps.LatLng(
        selectedAddress.location.lat,
        selectedAddress.location.lng
      );

      directionsService.route(
        {
          origin,
          destination,
          travelMode: window.google.maps.TravelMode.DRIVING
        },
        (result, status) => {
          if (status === window.google.maps.DirectionsStatus.OK) {
            setDirections(result);
            
            // الحصول على المسافة
            const distanceInMeters = result.routes[0].legs[0].distance.value;
            const distanceInKm = distanceInMeters / 1000;
            setDistance(distanceInKm);
            
            // حساب رسوم التوصيل
            calculateDeliveryFee(distanceInKm);
          }
        }
      );
    }
  };

  const calculateDeliveryFee = (distanceKm) => {
    // أول 20 كيلو بسعر 1.350 دينار
    // بعد 20 كيلو: 0.100 دينار لكل كيلو إضافي
    let fee = 0;
    if (distanceKm <= 20) {
      fee = 1.350;
    } else {
      fee = 1.350 + (distanceKm - 20) * 0.100;
    }
    setDeliveryFee(parseFloat(fee.toFixed(3)));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // التحقق المسبق من البيانات
    if (!restaurantId) {
      setError('خطأ: لم يتم العثور على معرف المطعم');
      return;
    }
  
    if (!selectedDriver) {
      setError('يرجى اختيار سائق للتوصيل');
      return;
    }
  
    // التحقق من البيانات الأساسية
    const requiredFields = [
      'customerName', 
      'phone', 
      'address', 
      'amount'
    ];
  
    const missingFields = requiredFields.filter(field => 
      !deliveryInfo[field] || deliveryInfo[field].trim() === ''
    );
  
    if (missingFields.length > 0) {
      setError(`يرجى ملء الحقول التالية: ${missingFields.join(', ')}`);
      return;
    }
  
    // التحقق من صحة المبلغ
    const amount = parseFloat(deliveryInfo.amount);
    if (isNaN(amount) || amount <= 0) {
      setError('قيمة الطلب يجب أن تكون رقمًا موجبًا');
      return;
    }
  
    // التحقق من رصيد المطعم
    if (!restaurantData || restaurantData.balance < deliveryFee) {
      setError(`رصيد المطعم غير كافٍ. الرصيد الحالي: ${restaurantData?.balance || 0} د.ك، المطلوب: ${deliveryFee} د.ك`);
      return;
    }
  
    setSubmitting(true);
    setError('');
  
    try {
      // طباعة تفاصيل الطلب للتأكد
      console.log('تفاصيل الطلب:', {
        restaurantId,
        selectedDriver,
        deliveryInfo,
        restaurantData,
        distance,
        deliveryFee
      });
  
      // التأكد من وجود الكoleكشن والمرجع
      const ordersRef = collection(db, 'orders');
      if (!ordersRef) {
        throw new Error('لا يمكن الوصول إلى مجموعة الطلبات');
      }
  
      // إنشاء بيانات الطلب
      const orderData = {
        restaurantId: restaurantId,
        restaurantName: restaurantData?.name || 'مطعم غير معروف',
        restaurantLocation: restaurantData?.location || null,
        driverId: selectedDriver.id,
        driverName: selectedDriver.name,
        customerName: deliveryInfo.customerName.trim(),
        phone: deliveryInfo.phone.trim(),
        address: deliveryInfo.address.trim(),
        notes: deliveryInfo.notes?.trim() || '',
        amount: parseFloat(deliveryInfo.amount),
        paymentMethod: deliveryInfo.paymentMethod || 'نقدي',
        distance: distance || 0,
        deliveryFee: deliveryFee || 0,
        status: 'قيد التنفيذ 🚀',
        createdAt: serverTimestamp(),
        customerLocation: directions?.routes[0]?.legs[0]?.end_location 
          ? { 
              lat: directions.routes[0].legs[0].end_location.lat(), 
              lng: directions.routes[0].legs[0].end_location.lng() 
            } 
          : null
      };
  
      // إضافة الطلب
      const orderRef = await addDoc(ordersRef, orderData);
  
      // باقي عمليات التحديث
      // خصم رسوم التوصيل من رصيد المطعم
      const restaurantRef = doc(db, 'restaurants', restaurantId);
      await updateDoc(restaurantRef, {
        balance: (restaurantData.balance || 0) - deliveryFee
      });
  
      // إرسال إشعار للسائق
      await sendNotification('driver', selectedDriver.id, `طلب توصيل جديد من ${restaurantData.name}`);
  
      // حفظ المعاملة المالية
      await addDoc(collection(db, 'transactions'), {
        restaurantId: restaurantId,
        type: 'خصم',
        amount: deliveryFee,
        description: `رسوم توصيل للطلب #${orderRef.id}`,
        timestamp: serverTimestamp()
      });
  
      setSuccess(`تم إرسال الطلب بنجاح! رقم الطلب: ${orderRef.id}`);
      
      // إعادة تعيين النموذج
      setDeliveryInfo({
        customerName: '',
        phone: '',
        address: '',
        notes: '',
        amount: '',
        paymentMethod: 'نقدي'
      });
      setSelectedDriver(null);
      setDirections(null);
      setDistance(0);
      setDeliveryFee(0);
      
      // توجيه المستخدم إلى صفحة تفاصيل الطلب بعد 3 ثوان
      setTimeout(() => {
        navigate(`/restaurant/orders/${orderRef.id}`);
      }, 3000);
  
    } catch (error) {
      console.error('خطأ مفصل في إرسال الطلب:', {
        message: error.message,
        code: error.code,
        stack: error.stack
      });
      
      // رسائل خطأ مفصلة
      const errorMessages = {
        'permission-denied': 'ليس لديك إذن لإضافة طلب',
        'unavailable': 'مشكلة في الاتصال. تأكد من اتصالك بالإنترنت',
        'default': 'حدث خطأ غير متوقع. يرجى التحقق من البيانات والاتصال بالدعم'
      };
  
      setError(errorMessages[error.code] || errorMessages['default']);
    } finally {
      setSubmitting(false);
    }
  };

  const renderDriverMarkers = () => {
    return drivers.map(driver => (
      <Marker
        key={driver.id}
        position={driver.location}
        icon={{
          url: driver.id === selectedDriver?.id 
            ? '/images/selected-driver-marker.png' 
            : '/images/driver-marker.png',
          scaledSize: new window.google.maps.Size(40, 40)
        }}
        onClick={() => setSelectedDriver(driver)}
      />
    ));
  };

  return (
    <div className="restaurant-new-order-page">
      <RestaurantSidebar />
      <div className="restaurant-new-order-content">
        <RestaurantHeader restaurantName={restaurantData?.name || 'المطعم'} />
        
        <div className="new-order-container">
          <h1 className="page-title">إرسال طلب جديد</h1>
          
          {error && <div className="error-message">{error}</div>}
          {success && <div className="success-message">{success}</div>}
          
          <div className="new-order-grid">
            <div className="new-order-form-container">
              <form onSubmit={handleSubmit} className="new-order-form">
                <div className="form-section">
                  <h2>بيانات العميل</h2>
                  
                  <div className="form-group">
                    <label htmlFor="customerName">اسم العميل *</label>
                    <input
                      type="text"
                      id="customerName"
                      value={deliveryInfo.customerName}
                      onChange={(e) => setDeliveryInfo({...deliveryInfo, customerName: e.target.value})}
                      disabled={submitting}
                      required
                    />
                  </div>
                  
                  <div className="form-group">
                    <label htmlFor="phone">رقم الهاتف *</label>
                    <input
                      type="tel"
                      id="phone"
                      value={deliveryInfo.phone}
                      onChange={(e) => setDeliveryInfo({...deliveryInfo, phone: e.target.value})}
                      disabled={submitting}
                      required
                    />
                  </div>
                  
                  <div className="form-group">
                    <label htmlFor="address">العنوان *</label>
                    <MapboxAddressSearch onSelect={handleAddressSelect} />
                  </div>
                  
                  <div className="form-group">
                    <label htmlFor="notes">ملاحظات</label>
                    <textarea
                      id="notes"
                      value={deliveryInfo.notes}
                      onChange={(e) => setDeliveryInfo({...deliveryInfo, notes: e.target.value})}
                      disabled={submitting}
                    />
                  </div>
                </div>
                
                <div className="form-section">
                  <h2>تفاصيل الطلب</h2>
                  
                  <div className="form-group">
                    <label htmlFor="amount">قيمة الطلب (د.ك) *</label>
                    <input
                      type="number"
                      id="amount"
                      value={deliveryInfo.amount}
                      onChange={(e) => setDeliveryInfo({...deliveryInfo, amount: e.target.value})}
                      disabled={submitting}
                      step="0.001"
                      min="0"
                      required
                    />
                  </div>
                  
                  <div className="form-group">
                    <label htmlFor="paymentMethod">طريقة الدفع *</label>
                    <select
                      id="paymentMethod"
                      value={deliveryInfo.paymentMethod}
                      onChange={(e) => setDeliveryInfo({...deliveryInfo, paymentMethod: e.target.value})}
                      disabled={submitting}
                      required
                    >
                      <option value="نقدي">نقدي</option>
                      <option value="كي نت">كي نت</option>
                      <option value="بطاقة ائتمان">بطاقة ائتمان</option>
                    </select>
                  </div>
                  
                  {distance > 0 && (
                    <div className="delivery-info">
                      <div className="info-item">
                        <span className="info-label">المسافة:</span>
                        <span className="info-value">{distance.toFixed(2)} كم</span>
                      </div>
                      <div className="info-item">
                        <span className="info-label">رسوم التوصيل:</span>
                        <span className="info-value">{deliveryFee.toFixed(3)} د.ك</span>
                      </div>
                    </div>
                  )}
                </div>
                
                <div className="form-section">
                  <h2>اختر السائق</h2>
                  
                  {drivers.length === 0 ? (
                    <p className="no-drivers">لا يوجد سائقين متاحين حالياً</p>
                  ) : (
                    <div className="drivers-list">
                      {drivers.map(driver => (
                        <div 
                          key={driver.id} 
                          className={`driver-card ${selectedDriver?.id === driver.id ? 'selected' : ''}`}
                          onClick={() => setSelectedDriver(driver)}
                        >
                          <div className="driver-avatar">{driver.name.charAt(0)}</div>
                          <div className="driver-info">
                            <h3>{driver.name}</h3>
                            <p>{driver.phone}</p>
                          </div>
                          <div className="driver-status">متاح</div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                
                <div className="form-actions">
                  <button 
                    type="submit" 
                    className="submit-btn" 
                    disabled={submitting || drivers.length === 0}
                  >
                    {submitting ? 'جاري الإرسال...' : 'إرسال الطلب'}
                  </button>
                </div>
              </form>
            </div>
            
            <div className="map-container">
              {isLoaded ? (
                <GoogleMap
                  mapContainerStyle={{ width: '100%', height: '100%' }}
                  center={restaurantData?.location || { lat: 29.3759, lng: 47.9774 }}
                  zoom={12}
                  options={{
                    streetViewControl: false,
                    fullscreenControl: false
                  }}
                >
                  {/* موقع المطعم */}
                  {restaurantData?.location && (
                    <Marker
                      position={restaurantData.location}
                      icon={{
                        url: '/images/restaurant-marker.png',
                        scaledSize: new window.google.maps.Size(40, 40)
                      }}
                    />
                  )}
                  
                  {/* مواقع السائقين */}
                  {renderDriverMarkers()}
                  
                  {/* مسار التوصيل */}
                  {directions && (
                    <DirectionsRenderer
                      directions={directions}
                      options={{
                        suppressMarkers: true,
                        polylineOptions: {
                          strokeColor: '#4a90e2',
                          strokeWeight: 5
                        }
                      }}
                    />
                  )}
                </GoogleMap>
              ) : (
                <div className="map-loading">جاري تحميل الخريطة...</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewOrder;