// src/utils/notifications.js
import { getToken, onMessage } from "firebase/messaging";
import { messaging } from "../firebase";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { db } from "../firebase";

// طلب إذن الإشعارات
export const requestNotificationPermission = async () => {
  if (!messaging) {
    console.log('⚠️ Firebase Messaging غير متاح - تأكد من استخدام HTTPS أو localhost');
    return null;
  }
  
  try {
    console.log('🔹 طلب إذن الإشعارات...');
    const permission = await Notification.requestPermission();
    
    if (permission === 'granted') {
      console.log('✅ تم منح إذن الإشعارات');
      return getFCMToken();
    } else {
      console.log('❌ تم رفض إذن الإشعارات');
      return null;
    }
  } catch (error) {
    console.error('❌ خطأ في طلب إذن الإشعارات:', error);
    return null;
  }
};

// الحصول على رمز FCM
export const getFCMToken = async () => {
  if (!messaging) {
    return null;
  }
  
  try {
    const currentToken = await getToken(messaging, {
      vapidKey: 'SptifuBGYaJ8qu7vFlT4xFReDh1LLB_j3DG4mkYgu70'
    });
    
    if (currentToken) {
      console.log('✅ تم الحصول على رمز FCM');
      return currentToken;
    } else {
      console.log('⚠️ لا يمكن الحصول على الرمز');
      return null;
    }
  } catch (error) {
    console.error('❌ خطأ في جلب رمز FCM:', error);
    return null;
  }
};

// الاستماع للإشعارات
export const listenForMessages = () => {
  if (!messaging) {
    return;
  }
  
  onMessage(messaging, (payload) => {
    console.log('🔔 تم استلام إشعار:', payload);
    
    if (payload.notification) {
      const { title, body } = payload.notification;
      const notificationOptions = {
        body,
        icon: '/logo192.png'
      };
      
      // إظهار الإشعار
      try {
        new Notification(title, notificationOptions);
      } catch (error) {
        console.error('❌ خطأ في عرض الإشعار:', error);
      }
    }
  });
};

// إرسال إشعار
export const sendNotification = async (recipientType, recipientId, message) => {
  try {
    const notificationData = {
      recipientType,
      recipientId,
      message,
      isRead: false,
      timestamp: serverTimestamp(),
    };
    
    await addDoc(collection(db, "notifications"), notificationData);
    console.log('✅ تم إرسال الإشعار بنجاح');
    return true;
  } catch (error) {
    console.error('❌ خطأ في إرسال الإشعار:', error);
    return false;
  }
};

// تعديل دالة طلب الإذن للتوافق مع App.js
export const requestPermission = async () => {
  if (!messaging) {
    return;
  }
  return requestNotificationPermission();
};