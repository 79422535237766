// ✅ Header.js مع تخصيص الزر حسب الدور واسم المستخدم

import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getAuth, signOut } from 'firebase/auth';
import logo from '../assets/logo.png';

const Header = () => {
  const auth = getAuth();
  const user = auth.currentUser;
  const navigate = useNavigate();

  const [userName, setUserName] = useState("");
  const [userRole, setUserRole] = useState("");

  useEffect(() => {
    const name = localStorage.getItem("restaurantName") ||
                 localStorage.getItem("driverName") ||
                 localStorage.getItem("adminName") ||
                 "Atdelivery";
    const role = localStorage.getItem("userRole") || "";
    setUserName(name);
    setUserRole(role);
  }, []);

  const handleLogout = async () => {
    await signOut(auth);
    localStorage.clear();
    navigate("/login");
  };

  return (
    <header style={{
      display: 'flex', justifyContent: 'space-between', alignItems: 'center',
      padding: '15px', backgroundColor: '#222', color: 'white'
    }}>
      <img src={logo} alt="AT Delivery Logo" style={{ width: '150px' }} />

      <div style={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
        {user && (
          <span style={{ fontSize: '16px', color: '#ccc' }}>
            👋 مرحباً، {userName} ({userRole})
          </span>
        )}

        {user ? (
          <button
            onClick={handleLogout}
            style={{
              backgroundColor: '#dc3545', color: 'white', padding: '10px 20px',
              border: 'none', borderRadius: '5px', fontSize: '16px', cursor: 'pointer'
            }}
          >
            تسجيل الخروج
          </button>
        ) : (
          <Link to="/login">
            <button style={{
              backgroundColor: '#FFC107', color: 'black', padding: '10px 20px',
              border: 'none', borderRadius: '5px', fontSize: '16px', cursor: 'pointer'
            }}>
              تسجيل الدخول
            </button>
          </Link>
        )}
      </div>
    </header>
  );
};

export default Header;