import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { db } from "../firebase";
import { collection, getDocs, query, where } from "firebase/firestore";


const Dashboard = () => {
  const [restaurantCount, setRestaurantCount] = useState(0);
  const [driverCount, setDriverCount] = useState(0);
  const [completedOrders, setCompletedOrders] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // ✅ جلب عدد المطاعم
        const restaurantsSnapshot = await getDocs(collection(db, "restaurants"));
        setRestaurantCount(restaurantsSnapshot.size);

        // ✅ جلب عدد السائقين
        const driversSnapshot = await getDocs(collection(db, "drivers"));
        setDriverCount(driversSnapshot.size);

        // ✅ جلب عدد الطلبات المكتملة
        const ordersQuery = query(collection(db, "orders"), where("status", "==", "مكتمل"));
        const ordersSnapshot = await getDocs(ordersQuery);
        setCompletedOrders(ordersSnapshot.size);
      } catch (error) {
        console.error("❌ خطأ في جلب البيانات:", error.message);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      
      <div style={{ textAlign: "center", marginTop: "50px" }}>
        <h2>لوحة التحكم</h2>
        <p>مرحبًا بك في نظام إدارة ATDelivery</p>

        {/* 🔹 إحصائيات النظام */}
        <div style={{ display: "flex", justifyContent: "center", gap: "20px", marginTop: "20px" }}>
          <div style={{ backgroundColor: "#FFC107", padding: "20px", borderRadius: "10px", minWidth: "200px" }}>
            <h3>عدد المطاعم</h3>
            <p>{restaurantCount}</p>
          </div>

          <div style={{ backgroundColor: "#17a2b8", padding: "20px", borderRadius: "10px", minWidth: "200px", color: "white" }}>
            <h3>عدد السائقين</h3>
            <p>{driverCount}</p>
          </div>

          <div style={{ backgroundColor: "#28a745", padding: "20px", borderRadius: "10px", minWidth: "200px", color: "white" }}>
            <h3>الطلبات المكتملة</h3>
            <p>{completedOrders}</p>
          </div>
        </div>

        {/* 🔹 إدارة المطاعم والسائقين والطلبات - تصحيح المسارات */}
        <div style={{ marginTop: "30px" }}>
          <Link to="/admin/restaurant-management">
            <button style={{ backgroundColor: "#007bff", color: "white", padding: "12px 25px", margin: "10px", borderRadius: "8px", border: "none", fontSize: "16px", cursor: "pointer" }}>
              إدارة المطاعم
            </button>
          </Link>
          <Link to="/admin/driver-management">
            <button style={{ backgroundColor: "#17a2b8", color: "white", padding: "12px 25px", margin: "10px", borderRadius: "8px", border: "none", fontSize: "16px", cursor: "pointer" }}>
              إدارة السائقين
            </button>
          </Link>
          <Link to="/admin/orders">
            <button style={{ backgroundColor: "#28a745", color: "white", padding: "12px 25px", margin: "10px", borderRadius: "8px", border: "none", fontSize: "16px", cursor: "pointer" }}>
              إدارة الطلبات
            </button>
          </Link>
        </div>

        {/* 🔹 قسم الإشعارات - تصحيح المسار */}
        <div style={{ marginTop: "30px" }}>
          <Link to="/notifications">
            <button style={{ backgroundColor: "#dc3545", color: "white", padding: "12px 25px", margin: "10px", borderRadius: "8px", border: "none", fontSize: "16px", cursor: "pointer" }}>
              الإشعارات
            </button>
          </Link>
        </div>

      </div>
     
    </div>
  );
};

export default Dashboard;